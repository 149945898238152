import { Injectable } from "@angular/core";
import { FunctionUtils, RxjsUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { Circle, Map, Polygon } from "leaflet";
import { withLatestFrom } from "rxjs";
import { IncidentMapLayer, IncidentSharedDataState, SahMapUtils, TaskStatus, TASK_STATUS_IMPORTANCE_ORDER } from "../index";
import { CustomLeafletMapEvent, DEFAULT_MAP_AREA_PATH_OPTIONS, MapArea, MAP_AREA_PATH_OPTIONS } from "../models/incident-map.models";
import { Task } from "../models/task.models";
import { IncidentMapLayersService } from "./incident-map-layers.service";

@UntilDestroy()
@Injectable()
export class AreaService {
    private map: Map | undefined;

    constructor(private readonly mapLayersService: IncidentMapLayersService, private readonly store: Store) {}

    private get layer() {
        return this.mapLayersService.getMapLayer(IncidentMapLayer.Areas);
    }

    public initMapWithAreas(map: Map, areas: MapArea[], tasks: Task[]): void {
        this.map = map;
        this.loadAreas(areas, tasks);

        this.watchAreaCreate();
        this.watchAreaUpdate();
        this.watchAreaRemove();
    }

    public reloadAreas(areas: MapArea[], tasks: Task[]): void {
        this.layer.clearLayers();
        this.loadAreas(areas, tasks);
    }

    public showAreaOnMap(area: MapArea): void {
        const areaOnMap = this.getAreaFromMapLayer(area.data?.id);
        if (!areaOnMap) {
            return;
        }

        this.map?.fitBounds(areaOnMap.getBounds(), { animate: false });
    }

    private getAreaFromMapLayer(areaId: string | undefined): MapArea | undefined {
        return (this.layer.getLayers() as MapArea[]).find((layer) => layer.data?.id === areaId);
    }

    private loadAreas(areas: MapArea[], tasks: Task[]): void {
        areas.forEach((area) => {
            const mapArea = this.createMapArea(area);
            this.updateMapAreaStylingBasedOnAssignedTasks(mapArea, tasks);
        });
    }

    private createMapArea(area: MapArea): MapArea {
        const mapArea = SahMapUtils.createMutableMapArea(area);

        this.layer.addLayer(mapArea);
        this.map?.fire(CustomLeafletMapEvent.LoadArea, { area: mapArea });

        return mapArea;
    }

    private updateMapAreaStylingBasedOnAssignedTasks(mapArea: MapArea, tasks: Task[] | undefined): void {
        const areaTasks = tasks?.filter((task) => task.areaId === mapArea.data?.id);
        const mostImportantTaskStatus = areaTasks?.reduce((currentStatus: TaskStatus | null, task: Task) => {
            if (!currentStatus) {
                return task.status;
            }

            return TASK_STATUS_IMPORTANCE_ORDER.indexOf(currentStatus) > TASK_STATUS_IMPORTANCE_ORDER.indexOf(task.status)
                ? task.status
                : currentStatus;
        }, null);

        if (FunctionUtils.isNullOrUndefined(mostImportantTaskStatus)) {
            mapArea.setStyle(DEFAULT_MAP_AREA_PATH_OPTIONS);

            return;
        }

        mapArea.setStyle(MAP_AREA_PATH_OPTIONS[mostImportantTaskStatus]);
    }

    private watchAreaCreate(): void {
        this.store
            .select(IncidentSharedDataState.createdArea)
            .pipe(RxjsUtils.filterFalsy(), untilDestroyed(this))
            .subscribe((area) => {
                const matchingMapArea = this.getAreaFromMapLayer(area.data?.id);
                if (matchingMapArea) {
                    return;
                }

                const mapArea = this.createMapArea(area);
                mapArea.setStyle(DEFAULT_MAP_AREA_PATH_OPTIONS);
            });
    }

    private watchAreaUpdate(): void {
        this.store
            .select(IncidentSharedDataState.updatedArea)
            .pipe(RxjsUtils.filterFalsy(), withLatestFrom(this.store.select(IncidentSharedDataState.tasks)), untilDestroyed(this))
            .subscribe(([area, tasks]) => {
                const mapArea = this.getAreaFromMapLayer(area.data?.id);
                if (!mapArea) {
                    return;
                }

                mapArea.data = area.data;
                if (mapArea instanceof Circle && area instanceof Circle) {
                    mapArea.setRadius(area.getRadius());
                    mapArea.setLatLng(area.getLatLng());
                } else if (mapArea instanceof Polygon && area instanceof Polygon) {
                    mapArea.setLatLngs(area.getLatLngs());
                }

                this.updateMapAreaStylingBasedOnAssignedTasks(mapArea, tasks);
            });
    }

    private watchAreaRemove(): void {
        this.store
            .select(IncidentSharedDataState.removedAreaId)
            .pipe(RxjsUtils.filterFalsy(), untilDestroyed(this))
            .subscribe((removedAreaId) => {
                const mapAreaToRemove = this.getAreaFromMapLayer(removedAreaId);
                if (!mapAreaToRemove) {
                    return;
                }

                this.layer.removeLayer(mapAreaToRemove);
            });
    }
}
