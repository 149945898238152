import { Injectable } from "@angular/core";
import {
    ACTION_MARKER_TOOLS,
    AREA_DRAWING_TOOLS,
    IncidentError,
    IncidentSharedDataState,
    MapFilter,
    MapFiltersState,
    MapToolName,
    SidePanelViewOpenState,
    SidePanelViewType,
    Task,
    TaskError,
    TaskStatus,
} from "@dtm-frontend/search-and-help-shared-lib/incident";
import { ChipOption } from "@dtm-frontend/shared/ui";
import { Action, Selector, State, StateContext, Store } from "@ngxs/store";
import { catchError, EMPTY, finalize, tap } from "rxjs";
import { Operator, OperatorPilots, Pilot } from "../../shared/models/team.models";
import { IncidentApiService } from "../services/incident-api.service";
import { IncidentActions } from "./incident.actions";

interface IncidentStateModel {
    isProcessing: boolean;
    isIncidentMapDataProcessing: boolean;

    editedTask: Task | undefined;
    createTaskError: TaskError | undefined;
    updateTaskError: TaskError | undefined;
    removeTaskError: IncidentError | undefined;
    changeTaskStatusError: IncidentError | undefined;
    acceptPilotTaskError: TaskError | undefined;
    rejectPilotTaskError: TaskError | undefined;

    selectedAreaId: string | undefined;
    previewedAreaId: string | undefined;
    createAreaError: IncidentError | undefined;
    updateAreaError: IncidentError | undefined;
    removeAreaError: IncidentError | undefined;
    assignAreaToTaskError: IncidentError | undefined;
    detachAreaFromTaskError: IncidentError | undefined;

    createHandDrawingError: IncidentError | undefined;
    removeHandDrawingError: IncidentError | undefined;

    operators: Operator[] | undefined;
    operatorPilots: OperatorPilots | undefined;
    teamUavAccessories: ChipOption[];
    createTeamsError: IncidentError | undefined;
    attachTeamToTaskError: IncidentError | undefined;
    detachTeamFromTaskError: IncidentError | undefined;
    removeTeamError: IncidentError | undefined;

    selectedMapToolName: MapToolName;
    isSelectionEnabled: boolean;
    isHandDrawEnabled: boolean;
    isAreaDrawEnabled: boolean;
    isRulerEnabled: boolean;
    isActionMarkerEnabled: boolean;

    sidePanelViewOpenState: SidePanelViewOpenState;
    mapFiltersState: MapFiltersState;
}

const DEFAULT_STATE: IncidentStateModel = {
    isProcessing: false,
    isIncidentMapDataProcessing: false,

    editedTask: undefined,
    createTaskError: undefined,
    updateTaskError: undefined,
    removeTaskError: undefined,
    changeTaskStatusError: undefined,
    acceptPilotTaskError: undefined,
    rejectPilotTaskError: undefined,

    selectedAreaId: undefined,
    previewedAreaId: undefined,
    createAreaError: undefined,
    updateAreaError: undefined,
    removeAreaError: undefined,
    assignAreaToTaskError: undefined,
    detachAreaFromTaskError: undefined,

    createHandDrawingError: undefined,
    removeHandDrawingError: undefined,

    teamUavAccessories: [],
    operators: undefined,
    operatorPilots: undefined,
    createTeamsError: undefined,
    attachTeamToTaskError: undefined,
    detachTeamFromTaskError: undefined,
    removeTeamError: undefined,

    selectedMapToolName: MapToolName.Cursor,
    isSelectionEnabled: true,
    isAreaDrawEnabled: false,
    isHandDrawEnabled: false,
    isRulerEnabled: false,
    isActionMarkerEnabled: false,

    sidePanelViewOpenState: {
        [SidePanelViewType.AreaList]: false,
        [SidePanelViewType.AreaPreview]: false,
        [SidePanelViewType.AreaAssignTask]: false,
        [SidePanelViewType.TaskList]: false,
        [SidePanelViewType.TaskCreator]: false,
        [SidePanelViewType.TaskEditor]: false,
        [SidePanelViewType.TeamCreator]: false,
        [SidePanelViewType.MapLayers]: false,
        [SidePanelViewType.Chat]: false,
    },
    mapFiltersState: {
        [MapFilter.ActionMarkers]: false,
        [MapFilter.Areas]: false,
        [MapFilter.AreasWithCompletedTasksOnly]: false,
        [MapFilter.AreasWithPlannedTasksOnly]: false,
        [MapFilter.HandDrawingsAndNotes]: false,
    },
};

@State<IncidentStateModel>({
    name: "incident",
    defaults: DEFAULT_STATE,
})
@Injectable()
export class IncidentState {
    @Selector()
    public static isProcessing(state: IncidentStateModel): boolean {
        return state.isProcessing;
    }

    @Selector()
    public static selectedMapToolName(state: IncidentStateModel): MapToolName {
        return state.selectedMapToolName;
    }

    @Selector()
    public static selectedAreaId(state: IncidentStateModel): string | undefined {
        return state.selectedAreaId;
    }

    @Selector()
    public static previewedAreaId(state: IncidentStateModel): string | undefined {
        return state.previewedAreaId;
    }

    @Selector()
    public static isSelectionEnabled(state: IncidentStateModel): boolean {
        return state.isSelectionEnabled;
    }

    @Selector()
    public static isAreaDrawEnabled(state: IncidentStateModel): boolean {
        return state.isAreaDrawEnabled;
    }

    @Selector()
    public static isHandDrawEnabled(state: IncidentStateModel): boolean {
        return state.isHandDrawEnabled;
    }

    @Selector()
    public static isRulerEnabled(state: IncidentStateModel): boolean {
        return state.isRulerEnabled;
    }

    @Selector()
    public static isActionMarkerEnabled(state: IncidentStateModel): boolean {
        return state.isActionMarkerEnabled;
    }

    @Selector()
    public static createTaskError(state: IncidentStateModel): TaskError | undefined {
        return state.createTaskError;
    }

    @Selector()
    public static updateTaskError(state: IncidentStateModel): TaskError | undefined {
        return state.updateTaskError;
    }

    @Selector()
    public static removeTaskError(state: IncidentStateModel): IncidentError | undefined {
        return state.removeTaskError;
    }

    @Selector()
    public static changeTaskStatusError(state: IncidentStateModel): IncidentError | undefined {
        return state.changeTaskStatusError;
    }

    @Selector()
    public static acceptPilotTaskError(state: IncidentStateModel): TaskError | undefined {
        return state.acceptPilotTaskError;
    }

    @Selector()
    public static rejectPilotTaskError(state: IncidentStateModel): TaskError | undefined {
        return state.rejectPilotTaskError;
    }

    @Selector()
    public static isIncidentMapDataProcessing(state: IncidentStateModel): boolean {
        return state.isIncidentMapDataProcessing;
    }

    @Selector()
    public static createAreaError(state: IncidentStateModel): IncidentError | undefined {
        return state.createAreaError;
    }

    @Selector()
    public static updateAreaError(state: IncidentStateModel): IncidentError | undefined {
        return state.updateAreaError;
    }

    @Selector()
    public static removeAreaError(state: IncidentStateModel): IncidentError | undefined {
        return state.removeAreaError;
    }

    @Selector()
    public static createHandDrawingError(state: IncidentStateModel): IncidentError | undefined {
        return state.createHandDrawingError;
    }

    @Selector()
    public static removeHandDrawingError(state: IncidentStateModel): IncidentError | undefined {
        return state.removeHandDrawingError;
    }

    @Selector()
    public static editedTask(state: IncidentStateModel): Task | undefined {
        return state.editedTask;
    }

    @Selector()
    public static assignAreaToTaskError(state: IncidentStateModel): IncidentError | undefined {
        return state.assignAreaToTaskError;
    }

    @Selector()
    public static detachAreaFromTaskError(state: IncidentStateModel): IncidentError | undefined {
        return state.detachAreaFromTaskError;
    }

    @Selector()
    public static createTeamsError(state: IncidentStateModel): IncidentError | undefined {
        return state.createTeamsError;
    }

    @Selector()
    public static operators(state: IncidentStateModel): Operator[] | undefined {
        return state.operators;
    }

    @Selector()
    public static operatorPilots(state: IncidentStateModel): OperatorPilots | undefined {
        return state.operatorPilots;
    }

    @Selector()
    public static attachTeamToTaskError(state: IncidentStateModel): IncidentError | undefined {
        return state.attachTeamToTaskError;
    }

    @Selector()
    public static detachTeamFromTaskError(state: IncidentStateModel): IncidentError | undefined {
        return state.detachTeamFromTaskError;
    }

    @Selector()
    public static removeTeamError(state: IncidentStateModel): IncidentError | undefined {
        return state.removeTeamError;
    }

    @Selector()
    public static sidePanelViewOpenState(state: IncidentStateModel): SidePanelViewOpenState {
        return state.sidePanelViewOpenState;
    }

    @Selector()
    public static teamUavAccessories(state: IncidentStateModel): ChipOption[] {
        return state.teamUavAccessories;
    }

    @Selector()
    public static mapFiltersState(state: IncidentStateModel): MapFiltersState {
        return state.mapFiltersState;
    }

    constructor(private readonly incidentApiService: IncidentApiService, private readonly store: Store) {}

    @Action(IncidentActions.ResetState)
    public resetState(context: StateContext<IncidentStateModel>) {
        context.patchState(DEFAULT_STATE);
    }

    @Action(IncidentActions.SelectMapTool)
    public selectMapTool(context: StateContext<IncidentStateModel>, { mapToolName }: IncidentActions.SelectMapTool) {
        context.patchState({
            selectedMapToolName: mapToolName,
            isSelectionEnabled: mapToolName === MapToolName.Cursor,
            isAreaDrawEnabled: AREA_DRAWING_TOOLS.some((tool) => tool.name === mapToolName),
            isHandDrawEnabled: mapToolName === MapToolName.DrawPencil,
            isRulerEnabled: mapToolName === MapToolName.Ruler,
            isActionMarkerEnabled: ACTION_MARKER_TOOLS.some((tool) => tool.name === mapToolName),
        });
    }

    @Action(IncidentActions.SelectArea)
    public selectArea(context: StateContext<IncidentStateModel>, { areaId }: IncidentActions.SelectArea) {
        context.patchState({ selectedAreaId: areaId });
    }

    @Action(IncidentActions.OpenAreaPreview)
    public openAreaPreview(context: StateContext<IncidentStateModel>, { areaId }: IncidentActions.OpenAreaPreview) {
        context.dispatch(new IncidentActions.OpenSidePanel(SidePanelViewType.AreaPreview));
        context.patchState({ previewedAreaId: areaId });
    }

    @Action(IncidentActions.OpenTaskEditor)
    public openTaskEditor(context: StateContext<IncidentStateModel>, { task }: IncidentActions.OpenTaskEditor) {
        context.dispatch(new IncidentActions.OpenSidePanel(SidePanelViewType.TaskEditor));
        context.patchState({ editedTask: task });
    }

    @Action(IncidentActions.CreateTask)
    public createTask(context: StateContext<IncidentStateModel>, { formValues }: IncidentActions.CreateTask) {
        const incidentId = this.store.selectSnapshot(IncidentSharedDataState.incidentId);
        if (!incidentId) {
            return;
        }

        context.patchState({ isProcessing: true, createTaskError: undefined });

        return this.incidentApiService.createTask(incidentId, formValues).pipe(
            catchError((error) => {
                context.patchState({ createTaskError: error });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isProcessing: false }))
        );
    }

    @Action(IncidentActions.UpdateTask)
    public updateTask(context: StateContext<IncidentStateModel>, { taskId, formValues }: IncidentActions.UpdateTask) {
        const incidentId = this.store.selectSnapshot(IncidentSharedDataState.incidentId);
        if (!incidentId) {
            return;
        }

        context.patchState({ isProcessing: true, updateTaskError: undefined });

        return this.incidentApiService.updateTask(incidentId, taskId, formValues).pipe(
            catchError((error) => {
                context.patchState({ updateTaskError: error });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isProcessing: false }))
        );
    }

    @Action(IncidentActions.RemoveTask)
    public removeTask(context: StateContext<IncidentStateModel>, { task }: IncidentActions.RemoveTask) {
        const incidentId = this.store.selectSnapshot(IncidentSharedDataState.incidentId);
        if (!incidentId) {
            return;
        }

        context.patchState({ removeTaskError: undefined, isIncidentMapDataProcessing: true });

        return this.incidentApiService.removeTask(task.id, incidentId).pipe(
            catchError((error) => {
                context.patchState({ removeTaskError: error });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isIncidentMapDataProcessing: false }))
        );
    }

    @Action(IncidentActions.ChangeTaskStatus)
    public changeTaskStatus(context: StateContext<IncidentStateModel>, { status, task }: IncidentActions.ChangeTaskStatus) {
        const incidentId = this.store.selectSnapshot(IncidentSharedDataState.incidentId);
        if (!incidentId) {
            return;
        }

        context.patchState({ isIncidentMapDataProcessing: true, changeTaskStatusError: undefined });

        return this.incidentApiService.updateTaskStatus(status, incidentId, task.id).pipe(
            catchError((error) => {
                context.patchState({ changeTaskStatusError: error });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isIncidentMapDataProcessing: false }))
        );
    }

    @Action(IncidentActions.CreateArea)
    public createArea(context: StateContext<IncidentStateModel>, { mapArea }: IncidentActions.CreateArea) {
        const incidentId = this.store.selectSnapshot(IncidentSharedDataState.incidentId);
        if (!incidentId) {
            return;
        }

        context.patchState({ createAreaError: undefined, isIncidentMapDataProcessing: true });

        return this.incidentApiService.createArea(mapArea, incidentId).pipe(
            catchError((error) => {
                context.patchState({ createAreaError: error });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isIncidentMapDataProcessing: false }))
        );
    }

    @Action(IncidentActions.UpdateArea)
    public updateArea(context: StateContext<IncidentStateModel>, { mapArea }: IncidentActions.UpdateArea) {
        const incidentId = this.store.selectSnapshot(IncidentSharedDataState.incidentId);
        if (!incidentId) {
            return;
        }

        context.patchState({ updateAreaError: undefined, isIncidentMapDataProcessing: true });

        return this.incidentApiService.updateArea(mapArea, incidentId).pipe(
            catchError((error) => {
                context.patchState({ updateAreaError: error });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isIncidentMapDataProcessing: false }))
        );
    }

    @Action(IncidentActions.RemoveArea)
    public removeArea(context: StateContext<IncidentStateModel>, { mapArea }: IncidentActions.RemoveArea) {
        const incidentId = this.store.selectSnapshot(IncidentSharedDataState.incidentId);
        if (!incidentId) {
            return;
        }

        context.patchState({ removeAreaError: undefined, isIncidentMapDataProcessing: true });

        return this.incidentApiService.removeArea(mapArea, incidentId).pipe(
            catchError((error) => {
                context.patchState({ removeAreaError: error });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isIncidentMapDataProcessing: false }))
        );
    }

    @Action(IncidentActions.CreateHandDrawing)
    public createHandDrawing(context: StateContext<IncidentStateModel>, { drawing }: IncidentActions.CreateHandDrawing) {
        const incidentId = this.store.selectSnapshot(IncidentSharedDataState.incidentId);
        if (!incidentId) {
            return;
        }

        context.patchState({ createHandDrawingError: undefined, isIncidentMapDataProcessing: true });

        return this.incidentApiService.createHandDrawing(drawing, incidentId).pipe(
            catchError((createHandDrawingError) => {
                context.patchState({ createHandDrawingError });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isIncidentMapDataProcessing: false }))
        );
    }

    @Action(IncidentActions.RemoveHandDrawing)
    public removeHandDrawing(context: StateContext<IncidentStateModel>, { drawing }: IncidentActions.RemoveHandDrawing) {
        const incidentId = this.store.selectSnapshot(IncidentSharedDataState.incidentId);
        const drawingId = drawing.data?.id;

        if (!incidentId || !drawingId) {
            return;
        }

        context.patchState({ removeHandDrawingError: undefined, isIncidentMapDataProcessing: true });

        return this.incidentApiService.removeHandDrawing(drawingId, incidentId).pipe(
            catchError((error) => {
                context.patchState({ removeHandDrawingError: error });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isIncidentMapDataProcessing: false }))
        );
    }

    @Action(IncidentActions.AssignAreaToTask)
    public assignAreaToTask(context: StateContext<IncidentStateModel>, { task, area }: IncidentActions.AssignAreaToTask) {
        const incidentId = this.store.selectSnapshot(IncidentSharedDataState.incidentId);
        if (!incidentId) {
            return;
        }

        context.patchState({ assignAreaToTaskError: undefined, isIncidentMapDataProcessing: true });

        return this.incidentApiService.assignAreaToTask(incidentId, task, area).pipe(
            catchError((error) => {
                context.patchState({ assignAreaToTaskError: error });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isIncidentMapDataProcessing: false }))
        );
    }

    @Action(IncidentActions.DetachAreaFromTask)
    public detachAreaFromTask(context: StateContext<IncidentStateModel>, { task, area }: IncidentActions.DetachAreaFromTask) {
        const incidentId = this.store.selectSnapshot(IncidentSharedDataState.incidentId);
        if (!incidentId) {
            return;
        }

        context.patchState({ detachAreaFromTaskError: undefined, isIncidentMapDataProcessing: true });

        return this.incidentApiService.detachAreaFromTask(incidentId, task, area).pipe(
            catchError((error) => {
                context.patchState({ detachAreaFromTaskError: error });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isIncidentMapDataProcessing: false }))
        );
    }

    @Action(IncidentActions.AttachTeamToTask)
    public attachTeamToTask(context: StateContext<IncidentStateModel>, { task, team }: IncidentActions.AttachTeamToTask) {
        const incidentId = this.store.selectSnapshot(IncidentSharedDataState.incidentId);
        if (!incidentId) {
            return;
        }

        context.patchState({ attachTeamToTaskError: undefined });

        return this.incidentApiService.attachTeamToTask(incidentId, task, team).pipe(
            catchError((error) => {
                context.patchState({ attachTeamToTaskError: error });

                return EMPTY;
            })
        );
    }

    @Action(IncidentActions.DetachTeamFromTask)
    public detachTeamFromTask(context: StateContext<IncidentStateModel>, { task }: IncidentActions.DetachTeamFromTask) {
        const incidentId = this.store.selectSnapshot(IncidentSharedDataState.incidentId);
        if (!incidentId) {
            return;
        }

        context.patchState({ detachTeamFromTaskError: undefined });

        return this.incidentApiService.detachTeamFromTask(incidentId, task).pipe(
            catchError((error) => {
                context.patchState({ detachTeamFromTaskError: error });

                return EMPTY;
            })
        );
    }

    @Action(IncidentActions.CreateTeamsInIncident)
    public createTeamsInIncident(context: StateContext<IncidentStateModel>, { teams }: IncidentActions.CreateTeamsInIncident) {
        const incidentId = this.store.selectSnapshot(IncidentSharedDataState.incidentId);
        if (!incidentId) {
            return;
        }

        context.patchState({ isProcessing: true, createTeamsError: undefined });

        return this.incidentApiService.createTeams(incidentId, teams).pipe(
            catchError((error) => {
                context.patchState({ createTeamsError: error });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isProcessing: false }))
        );
    }

    @Action(IncidentActions.GetOperators)
    public getOperators(context: StateContext<IncidentStateModel>) {
        context.patchState({ isProcessing: true });

        return this.incidentApiService.getOperators().pipe(
            tap((operators) => context.patchState({ operators })),
            finalize(() => context.patchState({ isProcessing: false }))
        );
    }

    @Action(IncidentActions.GetPilots)
    public getPilots(context: StateContext<IncidentStateModel>, { operatorId }: IncidentActions.GetPilots) {
        const { operatorPilots } = context.getState();
        if (operatorPilots && operatorPilots[operatorId]) {
            return;
        }

        context.patchState({ isProcessing: true });

        return this.incidentApiService.getPilots(operatorId).pipe(
            tap((pilots) => {
                const selectedOperatorPilots = pilots.reduce((result: { [key: string]: Pilot }, pilot) => {
                    result[pilot.pilotId] = pilot;

                    return result;
                }, {});

                context.patchState({
                    operatorPilots: { ...(operatorPilots ?? {}), [operatorId]: selectedOperatorPilots },
                });
            }),
            finalize(() => context.patchState({ isProcessing: false }))
        );
    }

    @Action(IncidentActions.GetPilotsUavs)
    public getPilotsUavs(context: StateContext<IncidentStateModel>, { operatorId, pilotId }: IncidentActions.GetPilotsUavs) {
        const { operatorPilots } = context.getState();
        const selectedOperatorPilots = (operatorPilots ?? {})[operatorId];
        const selectedPilot = selectedOperatorPilots[pilotId];

        if (selectedPilot?.uavs?.length) {
            return;
        }

        context.patchState({ isProcessing: true });

        return this.incidentApiService.getPilotsUavs(pilotId).pipe(
            tap((uavs) =>
                context.patchState({
                    operatorPilots: {
                        ...(operatorPilots ?? {}),
                        [operatorId]: { ...(selectedOperatorPilots ?? {}), [pilotId]: { ...(selectedPilot ?? {}), uavs } },
                    },
                })
            ),
            finalize(() => context.patchState({ isProcessing: false }))
        );
    }

    @Action(IncidentActions.OpenSidePanel)
    public openSidePanel(context: StateContext<IncidentStateModel>, { sidePanelViewType }: IncidentActions.OpenSidePanel) {
        const clearedSidePanelState = Object.keys(SidePanelViewType).reduce(
            (result, key) => ({ ...result, [key]: false }),
            {}
        ) as SidePanelViewOpenState;

        context.patchState({ sidePanelViewOpenState: { ...clearedSidePanelState, [sidePanelViewType]: true } });
    }

    @Action(IncidentActions.CloseSidePanel)
    public closeSidePanel(context: StateContext<IncidentStateModel>) {
        const clearedSidePanelState = Object.keys(SidePanelViewType).reduce(
            (result, key) => ({ ...result, [key]: false }),
            {}
        ) as SidePanelViewOpenState;

        context.patchState({ sidePanelViewOpenState: clearedSidePanelState });
    }

    @Action(IncidentActions.GetTeamCreatorHints)
    public getTeamCreatorHints(context: StateContext<IncidentStateModel>) {
        const incidentId = this.store.selectSnapshot(IncidentSharedDataState.incidentId);
        if (!incidentId) {
            return;
        }

        context.patchState({ teamUavAccessories: undefined });

        return this.incidentApiService
            .getTeamCreatorHints(incidentId)
            .pipe(tap((teamUavAccessories) => context.patchState({ teamUavAccessories })));
    }

    @Action(IncidentActions.UpdateMapFilters)
    public updateMapFilters(context: StateContext<IncidentStateModel>, { mapFiltersState }: IncidentActions.UpdateMapFilters) {
        context.patchState({ mapFiltersState: mapFiltersState });
    }

    @Action(IncidentActions.RemoveTeam)
    public removeTeam(context: StateContext<IncidentStateModel>, { teamId }: IncidentActions.RemoveTeam) {
        const incidentId = this.store.selectSnapshot(IncidentSharedDataState.incidentId);
        if (!incidentId) {
            return;
        }

        context.patchState({ isProcessing: true, removeTeamError: undefined });

        return this.incidentApiService.removeTeam(incidentId, teamId).pipe(
            catchError((error) => {
                context.patchState({ removeTeamError: error });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isProcessing: false }))
        );
    }

    @Action(IncidentActions.AcceptPilotTask)
    public acceptPilotTask(context: StateContext<IncidentStateModel>, { task, shouldBeActivated }: IncidentActions.AcceptPilotTask) {
        const incidentId = this.store.selectSnapshot(IncidentSharedDataState.incidentId);
        if (!incidentId) {
            return;
        }

        context.patchState({ isProcessing: true, acceptPilotTaskError: undefined });
        const taskStatus = shouldBeActivated ? TaskStatus.Active : TaskStatus.Planned;

        return this.incidentApiService.updateTaskStatus(taskStatus, incidentId, task.id).pipe(
            catchError((error) => {
                context.patchState({ acceptPilotTaskError: error });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isProcessing: false }))
        );
    }

    @Action(IncidentActions.RejectPilotTask)
    public rejectPilotTask(context: StateContext<IncidentStateModel>, { task, rejectionReason }: IncidentActions.RejectPilotTask) {
        const incidentId = this.store.selectSnapshot(IncidentSharedDataState.incidentId);
        if (!incidentId) {
            return;
        }

        context.patchState({ isProcessing: true, rejectPilotTaskError: undefined });

        return this.incidentApiService.rejectPilotTask(incidentId, task.id, rejectionReason).pipe(
            catchError((error) => {
                context.patchState({ rejectPilotTaskError: error });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isProcessing: false }))
        );
    }
}
