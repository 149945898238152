import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { MapArea, MapTool, MAP_TOOLS } from "@dtm-frontend/search-and-help-shared-lib/incident";
import { LeafletMapComponent } from "@dtm-frontend/shared/map/leaflet";
import { ButtonTheme, ConfirmationDialogComponent, DialogService } from "@dtm-frontend/shared/ui";
import { TranslationHelperService } from "@dtm-frontend/shared/ui/i18n";
import { DtmToastComponent } from "@dtm-frontend/shared/ui/toast";
import { AnimationUtils, LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@ngneat/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { ActiveToast, DefaultGlobalConfig, ToastContainerDirective, ToastrService } from "ngx-toastr";
import { combineLatestWith, EMPTY, first, switchMap } from "rxjs";

interface IncidentCreatorMapComponentState {
    customArea: MapArea | undefined;
    isDrawingHelpHidden: boolean;
    selectedMapTool: MapTool | undefined;
    activeMapHintToast: ActiveToast<unknown> | undefined;
    areToolsEnabled: boolean;
}

const CIRCLE_MAP_TOOL = MAP_TOOLS.ShapeCircle;
const POLYGON_MAP_TOOL = MAP_TOOLS.ShapePolygon;

enum MapActionType {
    Edit = "Edit",
    ShapeCircle = "ShapeCircle",
    ShapePolygon = "ShapePolygon",
}
const TOAST_INFO_MESSAGE_KEYS = {
    [MapActionType.Edit]: {
        title: "sahClientLibIncidentCreator.incidentCreator.toastInfoTitle.edit",
        message: "sahClientLibIncidentCreator.incidentCreator.toastInfoMessage.edit",
    },
    [MapActionType.ShapeCircle]: {
        title: "sahClientLibIncidentCreator.incidentCreator.toastInfoTitle.drawCircle",
        message: "sahClientLibIncidentCreator.incidentCreator.toastInfoMessage.drawCircle",
    },
    [MapActionType.ShapePolygon]: {
        title: "sahClientLibIncidentCreator.incidentCreator.toastInfoTitle.drawPolygon",
        message: "sahClientLibIncidentCreator.incidentCreator.toastInfoMessage.drawPolygon",
    },
};

@UntilDestroy()
@Component({
    selector: "sah-client-lib-incident-creator-map[areToolsEnabled][customArea][isDrawingHelpHidden][selectedMapTool]",
    templateUrl: "./incident-creator-map.component.html",
    styleUrls: ["./incident-creator-map.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore, ToastrService],
    animations: [AnimationUtils.slideInAnimation()],
})
export class IncidentCreatorMapComponent {
    @ViewChild(LeafletMapComponent) public leafletMapComponent: LeafletMapComponent | undefined;
    @ViewChild(ToastContainerDirective) public set mapToolsToastContainer(value: ToastContainerDirective) {
        this.watchForMapToolsChange(value);
    }

    @Input() public set areToolsEnabled(value: BooleanInput) {
        this.localStore.patchState({ areToolsEnabled: coerceBooleanProperty(value) });
    }

    @Input() public set customArea(value: MapArea | undefined) {
        this.localStore.patchState({ customArea: value });
    }

    @Input() public set isDrawingHelpHidden(value: BooleanInput) {
        this.localStore.patchState({ isDrawingHelpHidden: coerceBooleanProperty(value) });
    }

    @Input() public set selectedMapTool(value: MapTool | undefined) {
        this.localStore.patchState({ selectedMapTool: value });
    }

    @Output() protected readonly drawingToolSelect = new EventEmitter<MapTool>();
    @Output() protected readonly incidentAreaRemove = new EventEmitter<void>();
    @Output() protected readonly areaCreate = new EventEmitter<MapArea>();

    protected readonly CIRCLE_MAP_TOOL = CIRCLE_MAP_TOOL;
    protected readonly POLYGON_MAP_TOOL = POLYGON_MAP_TOOL;

    protected readonly areToolsEnabled$ = this.localStore.selectByKey("areToolsEnabled");
    protected readonly customArea$ = this.localStore.selectByKey("customArea");
    protected readonly isDrawingHelpHidden$ = this.localStore.selectByKey("isDrawingHelpHidden");
    protected readonly selectedMapTool$ = this.localStore.selectByKey("selectedMapTool");

    constructor(
        private readonly dialogService: DialogService,
        private readonly localStore: LocalComponentStore<IncidentCreatorMapComponentState>,
        private readonly translationHelper: TranslationHelperService,
        private readonly translocoService: TranslocoService,
        private readonly toastrService: ToastrService
    ) {
        this.localStore.setState({
            customArea: undefined,
            activeMapHintToast: undefined,
            isDrawingHelpHidden: false,
            selectedMapTool: undefined,
            areToolsEnabled: false,
        });
    }

    protected removeIncidentArea(): void {
        this.dialogService
            .open(ConfirmationDialogComponent, {
                data: {
                    titleText: this.translocoService.translate(
                        "sahClientLibIncidentCreator.incidentCreator.removeIncidentAreaDialog.title"
                    ),
                    confirmationText: this.translocoService.translate(
                        "sahClientLibIncidentCreator.incidentCreator.removeIncidentAreaDialog.message"
                    ),
                    confirmButtonLabel: this.translocoService.translate(
                        "sahClientLibIncidentCreator.incidentCreator.removeIncidentAreaDialog.confirmButtonLabel"
                    ),
                    declineButtonLabel: this.translocoService.translate(
                        "sahClientLibIncidentCreator.incidentCreator.removeIncidentAreaDialog.cancelButtonLabel"
                    ),
                    theme: ButtonTheme.Warn,
                },
                disableClose: true,
            })
            .afterClosed()
            .pipe(RxjsUtils.filterFalsy(), untilDestroyed(this))
            .subscribe(() => this.incidentAreaRemove.emit());
    }

    private watchForMapToolsChange(toastrContainer: ToastContainerDirective) {
        if (!toastrContainer) {
            return;
        }

        this.toastrService.overlayContainer = toastrContainer;
        this.toastrService.toastrConfig = {
            ...DefaultGlobalConfig,
            autoDismiss: true,
            closeButton: true,
            disableTimeOut: true,
            toastComponent: DtmToastComponent,
            easeTime: 0,
        };

        this.localStore
            .selectByKey("areToolsEnabled")
            .pipe(
                combineLatestWith(this.localStore.selectByKey("customArea"), this.localStore.selectByKey("selectedMapTool")),
                switchMap(([areToolsEnabled, incidentArea, tool]) => {
                    this.toastrService.clear();

                    if (!areToolsEnabled) {
                        return EMPTY;
                    }

                    const isEditMode = !!incidentArea;
                    const editButtonLabel$ = this.translationHelper.waitForTranslation(
                        "sahClientLibIncidentCreator.incidentCreator.mapPanel.mapToolbarRemoveAreaLabel"
                    );
                    const translationKeys = isEditMode
                        ? TOAST_INFO_MESSAGE_KEYS[MapActionType.Edit]
                        : TOAST_INFO_MESSAGE_KEYS[(tool?.name ?? "") as MapActionType];

                    return editButtonLabel$.pipe(
                        switchMap((buttonLabel) =>
                            this.translationHelper
                                .waitForTranslation(translationKeys.message, {
                                    buttonLabel,
                                })
                                .pipe(combineLatestWith(this.translationHelper.waitForTranslation(translationKeys.title)))
                        ),
                        first()
                    );
                }),
                untilDestroyed(this)
            )
            .subscribe(([message, title]) => {
                this.localStore.patchState({ activeMapHintToast: this.toastrService.info(message, title) });
            });
    }
}
