import { DOCUMENT } from "@angular/common";
import { Inject, Injectable, OnDestroy } from "@angular/core";
import { UIState } from "@dtm-frontend/shared/ui";
import { RxjsUtils } from "@dtm-frontend/shared/utils";
import "@geoman-io/leaflet-geoman-free";
import { TranslocoService } from "@ngneat/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Actions, ofActionDispatched, Store } from "@ngxs/store";
import { Map } from "leaflet";
import { ActiveToast, IndividualConfig, ToastrService } from "ngx-toastr";
import { firstValueFrom, Subject, switchMap, tap, withLatestFrom } from "rxjs";
import {
    ActionMarkerService,
    IncidentMapLayer,
    IncidentMapLayersService,
    IncidentSharedDataActions,
    IncidentSharedDataState,
    MapArea,
    SahMapUtils,
} from "../index";
import { INCIDENT_AREA_PATH_OPTIONS } from "../models/incident-map.models";
import { AreaService } from "./area.service";
import { HandDrawingService } from "./hand-drawing.service";
import { TaskMarkerService } from "./task-marker.service";

const OFFLINE_WARNING_TOAST_CONFIG: Partial<IndividualConfig> = {
    disableTimeOut: true,
    closeButton: false,
    tapToDismiss: false,
    easeTime: 0,
};

@UntilDestroy()
@Injectable()
export class IncidentMapService implements OnDestroy {
    private readonly operationalSituationDataReloadedSubject = new Subject<void>();
    public readonly operationalSituationDataReloaded$ = this.operationalSituationDataReloadedSubject.asObservable();

    private mapInstance: Map | undefined;
    private offlineWarningToast: ActiveToast<unknown> | undefined;

    constructor(
        private readonly actionMarkerService: ActionMarkerService,
        private readonly actions$: Actions,
        private readonly areaService: AreaService,
        @Inject(DOCUMENT) private readonly document: Document,
        private readonly handDrawingService: HandDrawingService,
        private readonly mapLayersService: IncidentMapLayersService,
        private readonly store: Store,
        private readonly taskMarkerService: TaskMarkerService,
        private readonly toastrService: ToastrService,
        private readonly translocoService: TranslocoService
    ) {
        this.watchLanguageChangeAndUpdateGeoman();
        this.watchIncidentAreaUpdate();
        this.watchNetworkConnection();
        this.watchOperationalSituationReload();
    }

    private displayWarningOnNetworkOffline = (): void => {
        this.offlineWarningToast = this.toastrService.warning(
            this.translocoService.translate("sahSharedLibIncident.incident.offlineWarningMessage"),
            undefined,
            OFFLINE_WARNING_TOAST_CONFIG
        );
    };

    private reloadOperationalSituationOnNetworkOnline = (): void => {
        if (this.offlineWarningToast) {
            this.toastrService.remove(this.offlineWarningToast.toastId);
            this.offlineWarningToast = undefined;
        }

        this.toastrService.info(this.translocoService.translate("sahSharedLibIncident.incident.onlineMessage"));
        this.store.dispatch(IncidentSharedDataActions.ReloadOperationalSituation);
    };

    public ngOnDestroy() {
        const windowObject = this.document.defaultView;

        windowObject?.removeEventListener("offline", this.displayWarningOnNetworkOffline);
        windowObject?.removeEventListener("online", this.reloadOperationalSituationOnNetworkOnline);
    }

    public initMapWithLayers(mapInstance: Map) {
        this.mapInstance = mapInstance;

        return firstValueFrom(
            this.store.select(IncidentSharedDataState.incidentArea).pipe(
                RxjsUtils.filterFalsy(),
                withLatestFrom(
                    this.store.select(IncidentSharedDataState.areas),
                    this.store.select(IncidentSharedDataState.handDrawings),
                    this.store.select(IncidentSharedDataState.tasks)
                ),
                tap(([incidentArea, areas, handDrawings, tasks]) => {
                    mapInstance.pm.setLang(this.store.selectSnapshot(UIState.activeLanguage));
                    this.mapLayersService.loadMapLayers(mapInstance);

                    if (incidentArea) {
                        this.loadIncidentArea(incidentArea);
                    }

                    this.actionMarkerService.initMap(mapInstance);
                    this.areaService.initMapWithAreas(mapInstance, areas, tasks);
                    this.handDrawingService.initMapWithHandDrawings(mapInstance, handDrawings);
                    this.taskMarkerService.initMapWithTaskMarkers(mapInstance, areas, tasks);
                }),
                untilDestroyed(this)
            )
        );
    }

    private loadIncidentArea(incidentArea: MapArea): void {
        const layer = this.mapLayersService.getMapLayer(IncidentMapLayer.IncidentArea);
        if (layer.getLayers().length > 0) {
            layer.clearLayers();
        }

        incidentArea = SahMapUtils.createMutableMapArea(incidentArea);

        this.mapLayersService.getMapLayer(IncidentMapLayer.IncidentArea).addLayer(incidentArea);
        incidentArea.setStyle(INCIDENT_AREA_PATH_OPTIONS);
        this.mapInstance?.fitBounds(incidentArea.getBounds());
    }

    private watchLanguageChangeAndUpdateGeoman(): void {
        this.store
            .select(UIState.activeLanguage)
            .pipe(untilDestroyed(this))
            .subscribe((language) => this.mapInstance?.pm.setLang(language));
    }

    private watchIncidentAreaUpdate(): void {
        this.actions$
            .pipe(
                ofActionDispatched(IncidentSharedDataActions.UpdateIncidentAreaOnMap),
                tap(({ incidentArea }) => this.loadIncidentArea(incidentArea)),
                untilDestroyed(this)
            )
            .subscribe();
    }

    private watchNetworkConnection(): void {
        const windowObject = this.document.defaultView;

        windowObject?.addEventListener("offline", this.displayWarningOnNetworkOffline);
        windowObject?.addEventListener("online", this.reloadOperationalSituationOnNetworkOnline);
    }

    private watchOperationalSituationReload(): void {
        this.actions$
            .pipe(
                ofActionDispatched(IncidentSharedDataActions.ReloadOperationalSituation),
                tap(() => this.clearAllMapLayers()),
                switchMap(() =>
                    this.store.dispatch(
                        new IncidentSharedDataActions.GetOperationalSituation(this.store.selectSnapshot(IncidentSharedDataState.incidentId))
                    )
                ),
                untilDestroyed(this)
            )
            .subscribe(() => {
                const error = this.store.selectSnapshot(IncidentSharedDataState.incidentMapDataError);
                if (error) {
                    this.toastrService.error(this.translocoService.translate("sahSharedLibIncident.incident.incidentMapDataError"));

                    return;
                }

                this.toastrService.success(
                    this.translocoService.translate("sahSharedLibIncident.incident.operationSituationReloadedMessage")
                );

                this.reloadData();
                this.operationalSituationDataReloadedSubject.next();
            });
    }

    private reloadData(): void {
        if (!this.mapInstance) {
            return;
        }

        const { incidentArea, areas, handDrawings, tasks } = this.store.selectSnapshot(IncidentSharedDataState);

        if (incidentArea) {
            this.loadIncidentArea(incidentArea);
        }

        this.areaService.reloadAreas(areas, tasks);
        this.handDrawingService.reloadHandDrawings(handDrawings);
        this.taskMarkerService.reloadTaskMarkers(areas, tasks);
    }

    private clearAllMapLayers(): void {
        [
            this.mapLayersService.getMapLayer(IncidentMapLayer.IncidentArea),
            this.mapLayersService.getMapLayer(IncidentMapLayer.Areas),
            this.mapLayersService.getMapLayer(IncidentMapLayer.ActionMarkers),
            this.mapLayersService.getMapLayer(IncidentMapLayer.HandDrawings),
            this.mapLayersService.getMapLayer(IncidentMapLayer.TaskMarkers),
        ].forEach((layer) => layer.clearLayers());
    }
}
