import { Injectable } from "@angular/core";
import {
    CustomLeafletMapEvent,
    IncidentMapLayer,
    IncidentMapLayersService,
    IncidentSharedDataState,
    MapArea,
    MapToolName,
    SahMapUtils,
    Task,
} from "@dtm-frontend/search-and-help-shared-lib/incident";
import { ButtonTheme, ConfirmationDialogComponent, DialogService } from "@dtm-frontend/shared/ui";
import { RxjsUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@ngneat/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { Map } from "leaflet";
import { ToastrService } from "ngx-toastr";
import { switchMap } from "rxjs";
import { GeomanUtils } from "../../geoman/index";
import { AreaNameChangeDialogComponent } from "../components/area-side-panel/area-name-change-dialog/area-name-change-dialog.component";
import { IncidentActions } from "../state/incident.actions";
import { IncidentState } from "../state/incident.state";
import { IncidentMapFiltersService } from "./incident-map-filters.service";

@UntilDestroy()
@Injectable()
export class AreaEditService {
    private map: Map | undefined;

    constructor(
        private readonly dialogService: DialogService,
        private readonly mapFiltersService: IncidentMapFiltersService,
        private readonly mapLayersService: IncidentMapLayersService,
        private readonly store: Store,
        private readonly translocoService: TranslocoService,
        private readonly toastrService: ToastrService
    ) {}

    private get layer() {
        return this.mapLayersService.getMapLayer(IncidentMapLayer.Areas);
    }

    public initMap(map: Map): void {
        this.map = map;

        this.watchAreaUpdateAndReselect();
        this.watchAreaRemoveAndDeselect();
    }

    public getAreaFromMapLayer(areaId: string | undefined): MapArea | undefined {
        return (this.layer.getLayers() as MapArea[]).find((layer) => layer.data?.id === areaId);
    }

    public createArea(area: MapArea): void {
        this.store
            .dispatch(new IncidentActions.CreateArea(area))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                area.remove();
                const error = this.store.selectSnapshot(IncidentState.createAreaError);

                if (error) {
                    this.toastrService.error(this.translocoService.translate("sahClientLibIncident.incident.createAreaError"));

                    return;
                }
            });
    }

    public selectArea(area: MapArea | undefined, shouldFitBounds = false): void {
        if (area?.data?.id && this.mapFiltersService.isAreaHidden(area.data.id)) {
            this.toastrService.warning(this.translocoService.translate("sahClientLibIncident.incident.areaHiddenMessage"));

            return;
        }

        const selectedArea = this.getAreaFromMapLayer(area?.data?.id);
        this.store.dispatch(new IncidentActions.SelectArea(selectedArea?.data?.id));

        if (!selectedArea) {
            return;
        }

        this.store.dispatch(new IncidentActions.SelectMapTool(MapToolName.Cursor));
        selectedArea.bringToFront();

        if (shouldFitBounds) {
            this.map?.fitBounds(selectedArea.getBounds(), { animate: false });
        }
    }

    public selectAreaById(areaId: string, shouldFitBounds = false): void {
        const selectedArea = this.getAreaFromMapLayer(areaId);
        if (!selectedArea) {
            return;
        }

        this.selectArea(selectedArea, shouldFitBounds);
    }

    public previewArea(area: MapArea): void {
        this.store.dispatch(new IncidentActions.OpenAreaPreview(area?.data?.id));
    }

    public updateArea(area: MapArea): void {
        this.store
            .dispatch(new IncidentActions.UpdateArea(area))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(IncidentState.updateAreaError);

                if (error) {
                    this.toastrService.error(this.translocoService.translate("sahClientLibIncident.incident.updateAreaError"));
                    this.map?.fire(CustomLeafletMapEvent.RevertSelectedAreaPosition);

                    return;
                }
            });
    }

    public updateAreaName(area: MapArea): void {
        if (!area.data) {
            return;
        }

        this.dialogService
            .open(AreaNameChangeDialogComponent, { data: { areaName: area.data.name } })
            .afterClosed()
            .pipe(
                RxjsUtils.filterFalsy(),
                switchMap((updatedName) =>
                    this.store.dispatch(new IncidentActions.UpdateArea(SahMapUtils.createMutableMapArea(area, { name: updatedName })))
                ),
                untilDestroyed(this)
            )
            .subscribe(() => {
                if (this.store.selectSnapshot(IncidentState.updateAreaError)) {
                    this.toastrService.error(this.translocoService.translate("sahClientLibIncident.incident.updateAreaNameError"));

                    return;
                }

                this.store.dispatch(new IncidentActions.OpenAreaPreview(area?.data?.id));
            });
    }

    public removeArea(area: MapArea): void {
        const areaToRemove = this.getAreaFromMapLayer(area.data?.id);
        if (!areaToRemove) {
            return;
        }

        this.selectArea(areaToRemove, true);
        this.dialogService
            .open(ConfirmationDialogComponent, {
                data: {
                    titleText: this.translocoService.translate("sahClientLibIncident.areaSidePanel.confirmAreaRemoveTitle", {
                        areaName: area.data?.name,
                    }),
                    confirmationText: this.translocoService.translate("sahClientLibIncident.incident.confirmMessage"),
                    confirmButtonLabel: this.translocoService.translate("sahClientLibIncident.incident.confirmButtonLabel"),
                    declineButtonLabel: this.translocoService.translate("sahClientLibIncident.incident.declineButtonLabel"),
                    theme: ButtonTheme.Warn,
                },
                disableClose: true,
            })
            .afterClosed()
            .pipe(
                RxjsUtils.filterFalsy(),
                switchMap(() => this.store.dispatch(new IncidentActions.RemoveArea(areaToRemove))),
                untilDestroyed(this)
            )
            .subscribe(() => {
                const error = this.store.selectSnapshot(IncidentState.removeAreaError);

                if (error) {
                    this.toastrService.error(this.translocoService.translate("sahClientLibIncident.areaSidePanel.areaRemoveError"));

                    return;
                }

                this.toastrService.success(this.translocoService.translate("sahClientLibIncident.areaSidePanel.areaRemoveSuccessMessage"));
            });
    }

    public assignedNewTaskToArea(task: Task, area: MapArea | undefined): void {
        if (!area?.data?.id || !task.id) {
            return;
        }

        this.store
            .dispatch(new IncidentActions.AssignAreaToTask(task, area))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(IncidentState.assignAreaToTaskError);
                if (!error) {
                    return;
                }

                this.toastrService.error(this.translocoService.translate("sahClientLibIncident.areaSidePanel.assignTaskError"));
            });
    }

    public detachAreaFromTask(area: MapArea | undefined, task: Task): void {
        if (!area?.data?.id || !task.id) {
            return;
        }

        this.dialogService
            .open(ConfirmationDialogComponent, {
                data: {
                    titleText: this.translocoService.translate("sahClientLibIncident.areaSidePanel.confirmDetachAreaFromTaskTitle"),
                    confirmationText: this.translocoService.translate("sahClientLibIncident.areaSidePanel.confirmMessage"),
                    confirmButtonLabel: this.translocoService.translate("sahClientLibIncident.areaSidePanel.confirmButtonLabel"),
                    declineButtonLabel: this.translocoService.translate("sahClientLibIncident.areaSidePanel.declineButtonLabel"),
                    theme: ButtonTheme.Warn,
                },
                disableClose: true,
            })
            .afterClosed()
            .pipe(
                RxjsUtils.filterFalsy(),
                switchMap(() => this.store.dispatch(new IncidentActions.DetachAreaFromTask(task, area))),
                untilDestroyed(this)
            )
            .subscribe(() => {
                const error = this.store.selectSnapshot(IncidentState.detachAreaFromTaskError);

                if (error) {
                    this.toastrService.error(this.translocoService.translate("sahClientLibIncident.areaSidePanel.detachAreaFromTaskError"));

                    return;
                }

                this.toastrService.success(
                    this.translocoService.translate("sahClientLibIncident.areaSidePanel.detachAreaFromTaskSuccessMessage")
                );
            });
    }

    private watchAreaUpdateAndReselect(): void {
        this.store
            .select(IncidentSharedDataState.updatedArea)
            .pipe(RxjsUtils.filterFalsy(), untilDestroyed(this))
            .subscribe((area) => {
                const mapArea = this.getAreaFromMapLayer(area.data?.id);
                if (!mapArea) {
                    return;
                }

                const selectedAreaId = this.store.selectSnapshot(IncidentState.selectedAreaId);
                const isUpdatedAreaSelected = selectedAreaId === mapArea.data?.id;
                if (isUpdatedAreaSelected) {
                    GeomanUtils.enableEditAndDrag(mapArea);
                }
            });
    }

    private watchAreaRemoveAndDeselect(): void {
        this.store
            .select(IncidentSharedDataState.removedAreaId)
            .pipe(RxjsUtils.filterFalsy(), untilDestroyed(this))
            .subscribe((removedAreaId) => {
                const selectedAreaId = this.store.selectSnapshot(IncidentState.selectedAreaId);
                if (selectedAreaId === removedAreaId) {
                    this.store.dispatch(new IncidentActions.SelectArea(undefined));
                }
            });
    }
}
