import { Injectable } from "@angular/core";
import { ButtonTheme, ConfirmationDialogComponent, DialogService } from "@dtm-frontend/shared/ui";
import { RxjsUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@ngneat/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { Map } from "leaflet";
import { ToastrService } from "ngx-toastr";
import { switchMap } from "rxjs";
import { ActionMarker, ActionMarkerData, CustomLeafletMapEvent } from "../models/incident-map.models";
import { IncidentSharedDataActions } from "../state/incident-shared-data.actions";
import { IncidentSharedDataState } from "../state/incident-shared-data.state";
import { IncidentMapLayer, IncidentMapLayersService } from "./incident-map-layers.service";

@UntilDestroy()
@Injectable()
export class ActionMarkerService {
    private map: Map | undefined;

    constructor(
        private readonly dialogService: DialogService,
        private readonly mapLayersService: IncidentMapLayersService,
        private readonly store: Store,
        private readonly translocoService: TranslocoService,
        private readonly toastrService: ToastrService
    ) {}

    public initMap(map: Map) {
        this.map = map;
    }

    public getSelectedMapMarker(): ActionMarker | undefined {
        return (this.mapLayersService.getMapLayer(IncidentMapLayer.ActionMarkers).getLayers() as ActionMarker[]).find(
            (mapMarker) => mapMarker.data?.id === this.store.selectSnapshot(IncidentSharedDataState.selectedActionMarkerData)?.id
        );
    }

    public toggleSelectedMarkerDragging(isDraggable: boolean): void {
        const selectedMapMarker = this.getSelectedMapMarker();
        if (isDraggable) {
            selectedMapMarker?.dragging?.enable();

            return;
        }

        selectedMapMarker?.dragging?.disable();
    }

    public selectActionMarker(markerData: Partial<ActionMarkerData> | undefined) {
        this.toggleSelectedMarkerDragging(false);
        this.store.dispatch(new IncidentSharedDataActions.SelectActionMarker(markerData));
    }

    public updateMarkerPosition(marker: ActionMarker): void {
        const { lat, lng } = marker.getLatLng();
        this.updateActionMarker({ ...marker.data, location: { latitude: lat, longitude: lng } });
    }

    public addActionMarker(markerData: Partial<ActionMarkerData>): void {
        this.store
            .dispatch(new IncidentSharedDataActions.CreateActionMarker(markerData))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(IncidentSharedDataState.createActionMarkerError);
                if (error) {
                    this.toastrService.error(this.translocoService.translate("sahSharedLibIncident.actionMarkers.createActionMarkerError"));

                    return;
                }

                const addPhotoError = this.store.selectSnapshot(IncidentSharedDataState.addActionMarkerPhotoError);
                if (addPhotoError) {
                    this.toastrService.error(
                        this.translocoService.translate("sahSharedLibIncident.actionMarkers.addActionMarkerPhotoError")
                    );
                }

                this.map?.fire(CustomLeafletMapEvent.CreateActionMarker, { data: markerData });
            });
    }

    public updateActionMarker(markerData: Partial<ActionMarkerData>): void {
        this.store
            .dispatch(new IncidentSharedDataActions.UpdateActionMarker(markerData))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const dataError = this.store.selectSnapshot(IncidentSharedDataState.updateActionMarkerError);
                if (dataError) {
                    this.toastrService.error(this.translocoService.translate("sahSharedLibIncident.actionMarkers.updateActionMarkerError"));

                    this.map?.fire(CustomLeafletMapEvent.OnActionMarkerUpdateFail);

                    return;
                }

                const updatePhotoError = this.store.selectSnapshot(IncidentSharedDataState.addActionMarkerPhotoError);
                if (updatePhotoError) {
                    this.toastrService.error(
                        this.translocoService.translate("sahSharedLibIncident.actionMarkers.updateActionMarkerPhotoError")
                    );
                }

                const removePhotoError = this.store.selectSnapshot(IncidentSharedDataState.removeActionMarkerPhotoError);
                if (removePhotoError) {
                    this.toastrService.error(
                        this.translocoService.translate("sahSharedLibIncident.actionMarkers.removeActionMarkerPhotoError")
                    );
                }

                this.map?.fire(CustomLeafletMapEvent.OnActionMarkerUpdate, { data: markerData });
            });
    }

    public removeActionMarker(markerId: string | undefined): void {
        if (!markerId) {
            return;
        }

        this.dialogService
            .open(ConfirmationDialogComponent, {
                data: {
                    titleText: this.translocoService.translate("sahSharedLibIncident.actionMarkers.removeActionMarkerConfirmTitle"),
                    confirmationText: this.translocoService.translate("sahSharedLibIncident.actionMarkers.confirmMessage"),
                    confirmButtonLabel: this.translocoService.translate("sahSharedLibIncident.actionMarkers.confirmButtonLabel"),
                    declineButtonLabel: this.translocoService.translate("sahSharedLibIncident.actionMarkers.declineButtonLabel"),
                    theme: ButtonTheme.Warn,
                },
                disableClose: true,
            })
            .afterClosed()
            .pipe(
                RxjsUtils.filterFalsy(),
                switchMap(() => this.store.dispatch(new IncidentSharedDataActions.RemoveActionMarker(markerId))),
                untilDestroyed(this)
            )
            .subscribe(() => {
                const error = this.store.selectSnapshot(IncidentSharedDataState.removeActionMarkerError);
                if (error) {
                    this.toastrService.error(this.translocoService.translate("sahSharedLibIncident.actionMarkers.removeActionMarkerError"));

                    return;
                }

                this.map?.fire(CustomLeafletMapEvent.RemoveActionMarker);
            });
    }

    public loadActionMarkerPhoto(markerData: Partial<ActionMarkerData>): void {
        this.store
            .dispatch(new IncidentSharedDataActions.GetActionMarkerPhoto(markerData))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(IncidentSharedDataState.getActionMarkerPhotoError);
                if (error) {
                    this.toastrService.error(
                        this.translocoService.translate("sahSharedLibIncident.actionMarkers.getActionMarkerPhotoError")
                    );

                    return;
                }
            });
    }
}
