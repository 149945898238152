import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { ButtonTheme, ConfirmationDialogComponent, DialogService } from "@dtm-frontend/shared/ui";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@ngneat/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { combineLatestWith } from "rxjs";
import { map } from "rxjs/operators";
import { Team } from "../../../models/team.models";

interface TaskTeamBadgeMenuComponentState {
    attachedTeam: Team | undefined;
    availableTeams: Team[];
    isPreview: boolean;
}

@UntilDestroy()
@Component({
    selector: "sah-shared-lib-task-team-badge-menu",
    templateUrl: "task-team-badge-menu.component.html",
    styleUrls: ["task-team-badge-menu.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class TaskTeamBadgeMenuComponent {
    @Input() public set attachedTeam(value: Team | undefined) {
        this.localStore.patchState({ attachedTeam: value });
    }

    @Input() public set availableTeams(value: Team[] | undefined) {
        this.localStore.patchState({ availableTeams: value ?? [] });
    }

    @Input() public set isPreview(value: BooleanInput) {
        this.localStore.patchState({ isPreview: coerceBooleanProperty(value) });
    }

    protected readonly isPreview$ = this.localStore.selectByKey("isPreview");

    @Output() protected readonly teamAttach = new EventEmitter<Team>();
    @Output() protected readonly teamDetach = new EventEmitter<Team>();

    protected readonly attachedTeam$ = this.localStore.selectByKey("attachedTeam");
    protected readonly availableTeams$ = this.localStore.selectByKey("availableTeams").pipe(
        combineLatestWith(this.attachedTeam$),
        map(([teams, attachedTeam]) => teams.filter((team) => team.id !== attachedTeam?.teamId))
    );

    constructor(
        private readonly dialogService: DialogService,
        private readonly localStore: LocalComponentStore<TaskTeamBadgeMenuComponentState>,
        private readonly translocoService: TranslocoService
    ) {
        this.localStore.setState({
            attachedTeam: undefined,
            availableTeams: [],
            isPreview: false,
        });
    }

    protected attachTeam(team: Team): void {
        const attachedTeam = this.localStore.selectSnapshotByKey("attachedTeam");

        if (!attachedTeam) {
            this.teamAttach.emit(team);

            return;
        }

        this.dialogService
            .open(ConfirmationDialogComponent, {
                data: {
                    titleText: this.translocoService.translate("sahSharedLibIncident.tasks.taskTeamBadgeMenu.taskTeamAttachDialog.title"),
                    confirmationText: this.translocoService.translate(
                        "sahSharedLibIncident.tasks.taskTeamBadgeMenu.taskTeamAttachDialog.confirmationText",
                        {
                            attachedTeam: attachedTeam.name,
                            team: team.name,
                        }
                    ),
                    confirmButtonLabel: this.translocoService.translate(
                        "sahSharedLibIncident.tasks.taskTeamBadgeMenu.taskTeamAttachDialog.confirmButtonLabel"
                    ),
                },
            })
            .afterClosed()
            .pipe(RxjsUtils.filterFalsy(), untilDestroyed(this))
            .subscribe(() => this.teamAttach.emit(team));
    }

    protected detachTeam(team: Team): void {
        this.dialogService
            .open(ConfirmationDialogComponent, {
                data: {
                    titleText: this.translocoService.translate("sahSharedLibIncident.tasks.taskTeamBadgeMenu.taskTeamDetachDialog.title"),
                    confirmationText: this.translocoService.translate(
                        "sahSharedLibIncident.tasks.taskTeamBadgeMenu.taskTeamDetachDialog.confirmationText",
                        {
                            team: team.name,
                        }
                    ),
                    confirmButtonLabel: this.translocoService.translate(
                        "sahSharedLibIncident.tasks.taskTeamBadgeMenu.taskTeamDetachDialog.confirmButtonLabel"
                    ),
                    theme: ButtonTheme.Warn,
                },
            })
            .afterClosed()
            .pipe(RxjsUtils.filterFalsy(), untilDestroyed(this))
            .subscribe(() => this.teamDetach.emit(team));
    }
}
