import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, Output } from "@angular/core";
import {
    ControlValueAccessor,
    FormArray,
    FormControl,
    FormGroup,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
    Validators,
} from "@angular/forms";
import { ButtonTheme, ChipOption, ConfirmationDialogComponent, DialogService } from "@dtm-frontend/shared/ui";
import { FunctionUtils, LocalComponentStore, ONLY_WHITE_SPACES_VALIDATION_PATTERN, RxjsUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@ngneat/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { combineLatestWith, EMPTY, Observable, startWith, switchMap, tap, withLatestFrom } from "rxjs";
import { map } from "rxjs/operators";
import {
    Operator,
    OperatorPilots,
    Pilot,
    TeamCreatorForm,
    TeamCreatorFormValues,
    UavCreatorForm,
    UavCreatorFormValues,
    UavType,
} from "../../models/team.models";

interface TeamCreatorComponentState {
    isProcessing: boolean;
    isDisabled: boolean;
    accessoryOptions: ChipOption[];
    operators: Operator[];
    operatorPilots: OperatorPilots | undefined;
    savedOperator: Operator | undefined | null;
    savedPilot: Pilot | undefined | null;
    hasUpdatedPilotsUavs: boolean;
}

const NAME_MAX_LENGTH = 150;
const UAV_TYPES = Object.values(UavType);

@UntilDestroy()
@Component({
    selector: "sah-client-lib-team-creator",
    templateUrl: "team-creator.component.html",
    styleUrls: ["team-creator.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        LocalComponentStore,
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => TeamCreatorComponent), multi: true },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => TeamCreatorComponent),
            multi: true,
        },
    ],
})
export class TeamCreatorComponent implements ControlValueAccessor, Validator {
    @Input() public set isProcessing(value: BooleanInput) {
        this.localStore.patchState({ isProcessing: coerceBooleanProperty(value) });
    }

    @Input() public set accessoryOptions(value: ChipOption[] | undefined) {
        this.localStore.patchState({ accessoryOptions: value ?? [] });
    }

    @Input() public set operators(value: Operator[] | undefined) {
        this.localStore.patchState({ operators: value ?? [] });
    }

    @Input() public set operatorPilots(value: OperatorPilots | undefined) {
        this.localStore.patchState({ operatorPilots: value });
    }

    @Output() protected readonly operatorChange = new EventEmitter<Operator>();
    @Output() protected readonly pilotChange = new EventEmitter<{ operator: Operator; pilot: Pilot }>();

    protected readonly UAV_TYPES = UAV_TYPES;
    protected readonly teamCreatorForm = new FormGroup<TeamCreatorForm>({
        name: new FormControl(null, [
            Validators.required,
            Validators.pattern(ONLY_WHITE_SPACES_VALIDATION_PATTERN),
            Validators.maxLength(NAME_MAX_LENGTH),
        ]),
        operator: new FormControl(null),
        pilot: new FormControl(null),
        pilotName: new FormControl(null, [Validators.maxLength(NAME_MAX_LENGTH)]),
        uavs: new FormArray<FormGroup<Partial<UavCreatorForm>>>([]),
    });

    protected readonly isProcessing$ = this.localStore.selectByKey("isProcessing");
    protected readonly isDisabled$ = this.localStore.selectByKey("isDisabled");
    protected readonly accessoryOptions$ = this.localStore.selectByKey("accessoryOptions");
    protected readonly operators$ = this.localStore.selectByKey("operators");
    protected readonly pilots$: Observable<Pilot[]> = this.localStore.selectByKey("operatorPilots").pipe(
        combineLatestWith(
            this.localStore.selectByKey("savedOperator"),
            this.localStore.selectByKey("savedPilot"),
            this.localStore.selectByKey("hasUpdatedPilotsUavs")
        ),
        tap(([operatorPilots, operator, pilot, hasUpdatedPilotUavs]) => {
            if (!operatorPilots || !operator?.id || !operatorPilots[operator.id] || !pilot?.pilotId) {
                return;
            }

            const pilotsUavs = operatorPilots[operator.id][pilot.pilotId]?.uavs;
            if (!pilotsUavs || !pilotsUavs.length || hasUpdatedPilotUavs) {
                return;
            }

            pilotsUavs.forEach((uav) => this.addUav({ ...uav }));
            this.localStore.patchState({ hasUpdatedPilotsUavs: true });
        }),
        map(([operatorPilots, operator]) => this.convertOperatorPilotsToPilots(operatorPilots, operator))
    );

    private onTouched = FunctionUtils.noop;
    private onValidationChange = FunctionUtils.noop;

    constructor(
        private readonly cdr: ChangeDetectorRef,
        private readonly dialogService: DialogService,
        private readonly localStore: LocalComponentStore<TeamCreatorComponentState>,
        private readonly translocoService: TranslocoService
    ) {
        this.localStore.setState({
            isProcessing: false,
            accessoryOptions: [],
            operators: [],
            operatorPilots: undefined,
            savedOperator: undefined,
            savedPilot: undefined,
            hasUpdatedPilotsUavs: false,
            isDisabled: this.teamCreatorForm.disabled,
        });

        this.watchOperatorUpdates();
        this.watchPilotUpdates();
    }

    public registerOnChange(fn: (value: Partial<TeamCreatorFormValues>) => void): void {
        this.teamCreatorForm.valueChanges.pipe(untilDestroyed(this)).subscribe(fn);
    }

    public registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    public registerOnValidatorChange(fn: () => void): void {
        this.onValidationChange = fn;
    }

    public writeValue(value: Partial<TeamCreatorFormValues>): void {
        this.teamCreatorForm.reset(
            {
                name: value.name,
                pilotName: value.pilotName,
            },
            { emitEvent: false }
        );

        value.uavs?.forEach((uav) => this.addUav(uav));
    }

    public setDisabledState(isDisabled: boolean): void {
        if (isDisabled) {
            this.teamCreatorForm.disable();
        } else {
            this.teamCreatorForm.enable();
        }

        this.localStore.patchState({ isDisabled });
    }

    public validate(): ValidationErrors | null {
        return this.teamCreatorForm.invalid ? { invalidTeamCreator: true } : null;
    }

    protected addUav(uav?: Partial<UavCreatorFormValues>): void {
        this.teamCreatorForm.controls.uavs.push(
            new FormGroup<Partial<UavCreatorForm>>({
                name: new FormControl(uav?.name ?? null, [
                    Validators.required,
                    Validators.pattern(ONLY_WHITE_SPACES_VALIDATION_PATTERN),
                    Validators.maxLength(NAME_MAX_LENGTH),
                ]),
                type: new FormControl(uav?.type ?? null),
                equipment: new FormControl(uav?.equipment ?? null),
            })
        );
    }

    protected removeUav(uavControl: FormGroup<Partial<UavCreatorForm>>, uavIndex: number): void {
        const { name, type, equipment } = uavControl.value ?? {};
        const isTypeEmpty = FunctionUtils.isNullOrUndefined(type);
        const isEquipmentEmpty = FunctionUtils.isNullOrUndefined(equipment) || equipment.length === 0;
        const canRemoveWithoutConfirmation = !name && isTypeEmpty && isEquipmentEmpty;

        if (canRemoveWithoutConfirmation) {
            this.teamCreatorForm.controls.uavs.removeAt(uavIndex);

            return;
        }

        this.dialogService
            .open(ConfirmationDialogComponent, {
                data: {
                    titleText: this.translocoService.translate("sahClientLibShared.teamCreator.removeUavDialog.title"),
                    confirmationText: this.translocoService.translate("sahClientLibShared.teamCreator.removeUavDialog.message"),
                    confirmButtonLabel: this.translocoService.translate(
                        "sahClientLibShared.teamCreator.removeUavDialog.confirmButtonLabel"
                    ),
                    declineButtonLabel: this.translocoService.translate("sahClientLibShared.teamCreator.removeUavDialog.cancelButtonLabel"),
                    theme: ButtonTheme.Warn,
                },
                disableClose: true,
            })
            .afterClosed()
            .pipe(RxjsUtils.filterFalsy(), untilDestroyed(this))
            .subscribe(() => {
                this.teamCreatorForm.controls.uavs.removeAt(uavIndex);
                this.cdr.detectChanges();
            });
    }

    protected areOperatorsEqual(left: Operator | null, right: Operator | null): boolean {
        return left?.id === right?.id;
    }

    protected arePilotsEqual(left: Pilot | null, right: Pilot | null): boolean {
        return left?.pilotId === right?.pilotId;
    }

    private watchOperatorUpdates(): void {
        const operatorControl = this.teamCreatorForm.controls.operator;

        operatorControl.valueChanges
            .pipe(
                startWith(operatorControl.value),
                switchMap((operator) => {
                    const savedOperator = this.localStore.selectSnapshotByKey("savedOperator");
                    if (savedOperator) {
                        return this.confirmFieldsValueChange().pipe(
                            tap((isConfirmed) => {
                                if (isConfirmed) {
                                    this.updateOperatorValueAndRelatedControls(operator, true);
                                } else {
                                    this.teamCreatorForm.controls.operator.setValue(savedOperator, { emitEvent: false });
                                }

                                this.cdr.detectChanges();
                            })
                        );
                    }

                    this.updateOperatorValueAndRelatedControls(operator, false);

                    return EMPTY;
                }),
                untilDestroyed(this)
            )
            .subscribe();
    }

    private watchPilotUpdates(): void {
        const { operator: operatorControl, pilot: pilotControl } = this.teamCreatorForm.controls;

        pilotControl.valueChanges
            .pipe(
                startWith(pilotControl.value),
                withLatestFrom(operatorControl.valueChanges.pipe(startWith(operatorControl.value))),
                switchMap(([pilot, operator]) => {
                    const savedPilot = this.localStore.selectSnapshotByKey("savedPilot");
                    if (savedPilot) {
                        return this.confirmFieldsValueChange().pipe(
                            tap((isConfirmed) => {
                                if (isConfirmed) {
                                    this.updatePilotValueAndRelatedControls(pilot, operator, true);
                                } else {
                                    this.teamCreatorForm.controls.pilot.setValue(savedPilot, { emitEvent: false });
                                }

                                this.cdr.detectChanges();
                            })
                        );
                    }

                    this.updatePilotValueAndRelatedControls(pilot, operator, false);

                    return EMPTY;
                }),
                untilDestroyed(this)
            )
            .subscribe();
    }

    private convertOperatorPilotsToPilots(operatorPilots: OperatorPilots | undefined, operator: Operator | null | undefined): Pilot[] {
        if (!operatorPilots || !operator?.id || !operatorPilots[operator.id]) {
            return [];
        }

        return Object.values(operatorPilots[operator.id]);
    }

    private resetFormValue(shouldClearUavs: boolean, operator?: Operator | null, pilot?: Pilot | null): void {
        const { name, uavs } = this.teamCreatorForm.value;
        this.teamCreatorForm.reset({ name, operator, pilot, uavs }, { emitEvent: false });

        if (shouldClearUavs) {
            this.teamCreatorForm.controls.uavs.clear();
        }
    }

    private updatePilotValueAndRelatedControls(pilot: Pilot | null, operator: Operator | null, shouldClearUavs: boolean): void {
        this.resetFormValue(shouldClearUavs, operator, pilot);
        this.localStore.patchState({ savedPilot: pilot, hasUpdatedPilotsUavs: false });

        if (!operator || !pilot) {
            return;
        }

        this.pilotChange.emit({ pilot, operator });
    }

    private updateOperatorValueAndRelatedControls(operator: Operator | null, shouldClearUavs: boolean): void {
        this.resetFormValue(shouldClearUavs, operator);
        this.localStore.patchState({ savedOperator: operator, savedPilot: null, hasUpdatedPilotsUavs: false });

        if (!operator) {
            return;
        }

        this.operatorChange.emit(operator);
    }

    private confirmFieldsValueChange(): Observable<boolean> {
        return this.dialogService
            .open(ConfirmationDialogComponent, {
                data: {
                    titleText: this.translocoService.translate("sahClientLibShared.teamCreator.changeFieldsValueDialog.title"),
                    confirmationText: this.translocoService.translate("sahClientLibShared.teamCreator.changeFieldsValueDialog.message"),
                    confirmButtonLabel: this.translocoService.translate(
                        "sahClientLibShared.teamCreator.changeFieldsValueDialog.confirmButtonLabel"
                    ),
                    declineButtonLabel: this.translocoService.translate(
                        "sahClientLibShared.teamCreator.changeFieldsValueDialog.cancelButtonLabel"
                    ),
                    theme: ButtonTheme.Warn,
                },
                disableClose: true,
            })
            .afterClosed()
            .pipe(untilDestroyed(this));
    }
}
