import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatLegacyInputModule as MatInputModule } from "@angular/material/legacy-input";
import { MatLegacyMenuModule as MatMenuModule } from "@angular/material/legacy-menu";
import { MatLegacyProgressSpinnerModule } from "@angular/material/legacy-progress-spinner";
import { MatLegacyTableModule as MatTableModule } from "@angular/material/legacy-table";
import { RouterLink } from "@angular/router";
import { SharedUiModule } from "@dtm-frontend/shared/ui";
import { DmsCoordinatesModule } from "@dtm-frontend/shared/ui/dms-coordinates";
import { getTranslocoInlineLoader, LanguageCode, SharedI18nModule } from "@dtm-frontend/shared/ui/i18n";
import { WebsocketService } from "@dtm-frontend/shared/websocket";
import { TRANSLOCO_SCOPE } from "@ngneat/transloco";
import { LetModule, PushModule } from "@ngrx/component";
import { SearchAndHelpSharedModule } from "../shared/search-and-help-shared.module";
import { ActionMarkerContextMenuComponent } from "./components/action-markers/action-marker-context-menu/action-marker-context-menu.component";
import { ActionMarkerEditorComponent } from "./components/action-markers/action-marker-editor/action-marker-editor.component";
import { ActionMarkerPhotoEditComponent } from "./components/action-markers/action-marker-photo/action-marker-photo-edit/action-marker-photo-edit.component";
import { ActionMarkerPhotoPreviewComponent } from "./components/action-markers/action-marker-photo/action-marker-photo-preview/action-marker-photo-preview.component";
import { ActionMarkerPreviewComponent } from "./components/action-markers/action-marker-preview/action-marker-preview.component";
import { ActionMarkerComponent } from "./components/action-markers/action-marker/action-marker.component";
import { ActionMarkersContextMenuDirective } from "./components/action-markers/action-markers-context-menu.directive";
import { ActionMarkersLayerDirective } from "./components/action-markers/action-markers-layer.directive";
import { ActionMarkersSelectListComponent } from "./components/action-markers/action-markers-select-list/action-markers-select-list.component";
import { AreaCardComponent } from "./components/areas/area-card/area-card.component";
import { IncidentChatComponent } from "./components/incident-chat/incident-chat.component";
import { IncidentListComponent } from "./components/incident-list/incident-list.component";
import { TaskCardComponent } from "./components/tasks/task-card/task-card.component";
import { TaskCreatorFormComponent } from "./components/tasks/task-creator-form/task-creator-form.component";
import { TaskMarkerIconComponent } from "./components/tasks/task-marker/task-marker-icon.component";
import { TaskPopupContentComponent } from "./components/tasks/task-popup-content/task-popup-content.component";
import { TaskPreviewComponent } from "./components/tasks/task-preview/task-preview.component";
import { TaskStatusBadgeMenuComponent } from "./components/tasks/task-status-badge-menu/task-status-badge-menu.component";
import { TaskStatusBadgeComponent } from "./components/tasks/task-status-badge/task-status-badge.component";
import { TaskTeamBadgeMenuComponent } from "./components/tasks/task-team-badge-menu/task-team-badge-menu.component";
import { TasksMapLayerDirective } from "./components/tasks/tasks-map-layer.directive";
import { IncidentCategoryListPipe } from "./pipes/incident-category-list.pipe";
import { IncidentSharedDataApiService } from "./services/incident-shared-data-api.service";

@NgModule({
    imports: [
        CommonModule,
        DmsCoordinatesModule,
        LetModule,
        MatDatepickerModule,
        MatInputModule,
        MatLegacyProgressSpinnerModule,
        MatMenuModule,
        MatTableModule,
        PushModule,
        ReactiveFormsModule,
        RouterLink,
        SearchAndHelpSharedModule,
        SharedI18nModule,
        SharedUiModule,
    ],
    declarations: [
        ActionMarkerComponent,
        ActionMarkerContextMenuComponent,
        ActionMarkerEditorComponent,
        ActionMarkerPhotoEditComponent,
        ActionMarkerPhotoPreviewComponent,
        ActionMarkerPreviewComponent,
        ActionMarkersLayerDirective,
        ActionMarkersContextMenuDirective,
        ActionMarkersSelectListComponent,
        AreaCardComponent,
        IncidentCategoryListPipe,
        IncidentChatComponent,
        IncidentListComponent,
        TaskCardComponent,
        TaskCreatorFormComponent,
        TaskMarkerIconComponent,
        TaskPopupContentComponent,
        TaskPreviewComponent,
        TasksMapLayerDirective,
        TaskStatusBadgeComponent,
        TaskStatusBadgeMenuComponent,
        TaskTeamBadgeMenuComponent,
    ],
    providers: [
        WebsocketService,
        {
            provide: TRANSLOCO_SCOPE,
            multi: true,
            useValue: {
                scope: "sahSharedLibIncident",
                loader: getTranslocoInlineLoader((language: LanguageCode) => import(`./assets/i18n/${language}.json`)),
            },
        },
    ],
    exports: [
        ActionMarkerComponent,
        ActionMarkerEditorComponent,
        ActionMarkerPreviewComponent,
        ActionMarkersLayerDirective,
        ActionMarkersContextMenuDirective,
        ActionMarkersSelectListComponent,
        AreaCardComponent,
        IncidentChatComponent,
        IncidentListComponent,
        TaskCardComponent,
        TaskCreatorFormComponent,
        TaskPreviewComponent,
        TasksMapLayerDirective,
        TaskStatusBadgeComponent,
        TaskStatusBadgeMenuComponent,
        TaskTeamBadgeMenuComponent,
    ],
})
export class IncidentSharedModule {
    public static forTest(): ModuleWithProviders<IncidentSharedModule> {
        return {
            ngModule: IncidentSharedModule,
            providers: [
                {
                    provide: IncidentSharedDataApiService,
                    useValue: null,
                },
                {
                    provide: WebsocketService,
                    useValue: null,
                },
            ],
        };
    }
}
