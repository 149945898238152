import { CommonModule, NgOptimizedImage } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { RouterLink } from "@angular/router";
import { KEYCLOAK_CONFIG, OfflineCapableAuthModule } from "@dtm-frontend/shared/auth";
import { SharedUiModule } from "@dtm-frontend/shared/ui";
import { getTranslocoInlineLoader, LanguageCode, SharedI18nModule } from "@dtm-frontend/shared/ui/i18n";
import { TRANSLOCO_SCOPE } from "@ngneat/transloco";
import { PushModule } from "@ngrx/component";
import { LoginPageComponent } from "./components/login-page/login-page.component";
import { PathInterpolationPipe } from "./pipes/path-interpolation.pipe";

@NgModule({
    imports: [CommonModule, NgOptimizedImage, OfflineCapableAuthModule, PushModule, RouterLink, SharedI18nModule, SharedUiModule],
    declarations: [LoginPageComponent, PathInterpolationPipe],
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            multi: true,
            useValue: {
                scope: "sahSharedLibShared",
                loader: getTranslocoInlineLoader((language: LanguageCode) => import(`./assets/i18n/${language}.json`)),
            },
        },
    ],
    exports: [LoginPageComponent, PathInterpolationPipe],
})
export class SearchAndHelpSharedModule {
    public static forTest(): ModuleWithProviders<SearchAndHelpSharedModule> {
        return {
            ngModule: SearchAndHelpSharedModule,
            providers: [
                {
                    provide: KEYCLOAK_CONFIG,
                    useValue: null,
                },
            ],
        };
    }
}
