import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";
import { Task } from "@dtm-frontend/search-and-help-shared-lib/incident";

@Component({
    selector: "sah-client-lib-accept-pilot-task-dialog",
    templateUrl: "accept-pilot-task-dialog.component.html",
    styleUrls: ["accept-pilot-task-dialog.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AcceptPilotTaskDialogComponent {
    constructor(
        @Inject(MAT_DIALOG_DATA) protected data: { task: Task },
        private readonly dialogRef: MatDialogRef<AcceptPilotTaskDialogComponent>
    ) {}

    protected accept(shouldBeActivated: boolean): void {
        this.dialogRef.close({ shouldBeActivated });
    }
}
