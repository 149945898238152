import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatLegacyInputModule as MatInputModule } from "@angular/material/legacy-input";
import { MatLegacyMenuModule as MatMenuModule } from "@angular/material/legacy-menu";
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from "@angular/material/legacy-progress-spinner";
import { LeafletMapModule } from "@dtm-frontend/shared/map/leaflet";
import { SharedUiModule } from "@dtm-frontend/shared/ui";
import { getTranslocoInlineLoader, LanguageCode, SharedI18nModule } from "@dtm-frontend/shared/ui/i18n";
import { TRANSLOCO_SCOPE } from "@ngneat/transloco";
import { LetModule, PushModule } from "@ngrx/component";
import { NgxsModule } from "@ngxs/store";
import { ToastContainerModule } from "ngx-toastr";
import { IncidentModule } from "../incident/incident.module";
import { SharedModule } from "../shared/shared.module";
import { IncidentCreatorDataComponent } from "./components/incident-creator/incident-creator-data/incident-creator-data.component";
import { IncidentCreatorMapComponent } from "./components/incident-creator/incident-creator-map/incident-creator-map.component";
import { IncidentCreatorTeamsComponent } from "./components/incident-creator/incident-creator-teams/incident-creator-teams.component";
import { IncidentCreatorComponent } from "./components/incident-creator/incident-creator.component";
import { IncidentCreatorApiService } from "./services/incident-creator-api.service";
import { IncidentCreatorState } from "./state/incident-creator.state";

@NgModule({
    imports: [
        CommonModule,
        IncidentModule,
        LeafletMapModule,
        LetModule,
        MatInputModule,
        MatMenuModule,
        MatProgressSpinnerModule,
        NgxsModule.forFeature([IncidentCreatorState]),
        PushModule,
        ReactiveFormsModule,
        SharedI18nModule,
        SharedModule,
        SharedUiModule,
        ToastContainerModule,
    ],
    declarations: [IncidentCreatorComponent, IncidentCreatorDataComponent, IncidentCreatorMapComponent, IncidentCreatorTeamsComponent],
    providers: [
        IncidentCreatorApiService,
        {
            provide: TRANSLOCO_SCOPE,
            multi: true,
            useValue: {
                scope: "sahClientLibIncidentCreator",
                loader: getTranslocoInlineLoader((language: LanguageCode) => import(`./assets/i18n/${language}.json`)),
            },
        },
    ],
})
export class IncidentCreatorModule {
    public static forTest(): ModuleWithProviders<IncidentCreatorModule> {
        return {
            ngModule: IncidentCreatorModule,
            providers: [
                {
                    provide: IncidentCreatorApiService,
                    useValue: null,
                },
            ],
        };
    }
}
