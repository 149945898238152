import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { MatLegacyDialogModule as MatDialogModule } from "@angular/material/legacy-dialog";
import { MatLegacyTabsModule as MatTabsModule } from "@angular/material/legacy-tabs";
import { IncidentSharedModule } from "@dtm-frontend/search-and-help-shared-lib/incident";
import { SharedUiModule } from "@dtm-frontend/shared/ui";
import { getTranslocoInlineLoader, LanguageCode, SharedI18nModule } from "@dtm-frontend/shared/ui/i18n";
import { TRANSLOCO_SCOPE } from "@ngneat/transloco";
import { LetModule, PushModule } from "@ngrx/component";
import { NgxsModule } from "@ngxs/store";
import { SharedModule } from "../shared/shared.module";
import { DashboardComponent } from "./components/dashboard/dashboard.component";
import { DashboardRoutingModule } from "./dashboard-routing.module";
import { DashboardApiService } from "./services/dashboard-api.service";
import { DashboardState } from "./state/dashboard.state";

@NgModule({
    imports: [
        CommonModule,
        DashboardRoutingModule,
        IncidentSharedModule,
        LetModule,
        MatDialogModule,
        MatTabsModule,
        NgxsModule.forFeature([DashboardState]),
        PushModule,
        SharedI18nModule,
        SharedModule,
        SharedUiModule,
    ],
    declarations: [DashboardComponent],
    providers: [
        DashboardApiService,
        {
            provide: TRANSLOCO_SCOPE,
            multi: true,
            useValue: {
                scope: "sahClientLibDashboard",
                loader: getTranslocoInlineLoader((language: LanguageCode) => import(`./assets/i18n/${language}.json`)),
            },
        },
    ],
})
export class DashboardModule {
    public static forTest(): ModuleWithProviders<DashboardModule> {
        return {
            ngModule: DashboardModule,
            providers: [
                {
                    provide: DashboardApiService,
                    useValue: null,
                },
            ],
        };
    }
}
