import { AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
    CustomLeafletMapEvent,
    DEFAULT_MAP_AREA_PATH_OPTIONS,
    MapArea,
    MapTool,
    MAP_TOOLS,
    SahMapUtils,
} from "@dtm-frontend/search-and-help-shared-lib/incident";
import { AuthState } from "@dtm-frontend/shared/auth";
import { TranslationHelperService } from "@dtm-frontend/shared/ui/i18n";
import { FunctionUtils, LocalComponentStore } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@ngneat/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { FeatureGroup, Map } from "leaflet";
import { ToastrService } from "ngx-toastr";
import { combineLatestWith, filter, first, firstValueFrom, switchMap, tap } from "rxjs";
import { map } from "rxjs/operators";
import { SahAbsolutePath } from "../../../shared/defaults/paths";
import { TeamCreatorFormValues } from "../../../shared/models/team.models";
import { IncidentCreatorErrorType, IncidentFormValues, Restriction } from "../../models/incident-creator.models";
import { IncidentCreatorActions } from "../../state/incident-creator.actions";
import { IncidentCreatorState } from "../../state/incident-creator.state";
import { IncidentCreatorMapComponent } from "./incident-creator-map/incident-creator-map.component";

enum IncidentCreatorStep {
    Data = "Data",
    Teams = "Teams",
}

interface IncidentCreatorComponentState {
    selectedMapTool: MapTool | undefined;
    customArea: MapArea | undefined;
    restrictedArea: MapArea | undefined | null;
    isDrawingHelpHidden: boolean;
    isCustomIncidentAreaSelected: boolean;
    activeStep: IncidentCreatorStep;
    editedIncidentId: string | undefined;
}

const DEFAULT_LANGUAGE = "pl";
const CIRCLE_MAP_TOOL = MAP_TOOLS.ShapeCircle;
const DASHBOARD_URL = SahAbsolutePath.Dashboard;

@UntilDestroy()
@Component({
    selector: "sah-client-lib-incident-creator",
    templateUrl: "./incident-creator.component.html",
    styleUrls: ["./incident-creator.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class IncidentCreatorComponent implements AfterViewInit, OnDestroy {
    @ViewChild(IncidentCreatorMapComponent) private incidentCreatorMapComponent: IncidentCreatorMapComponent | undefined;

    protected readonly CIRCLE_MAP_TOOL = CIRCLE_MAP_TOOL;
    protected readonly IncidentCreatorStep = IncidentCreatorStep;

    protected readonly airOperationsCoordinators$ = this.store.select(IncidentCreatorState.airOperationsCoordinators);
    protected readonly flightsCoordinators$ = this.store.select(IncidentCreatorState.flightsCoordinators);
    protected readonly institutions$ = this.store.select(IncidentCreatorState.institutions);
    protected readonly units$ = this.store.select(IncidentCreatorState.units);
    protected readonly restrictions$ = this.store.select(IncidentCreatorState.restrictions);
    protected readonly isEditMode$ = this.localStore.selectByKey("editedIncidentId").pipe(map(FunctionUtils.isTruthy));
    protected readonly userId$ = this.store.select(AuthState.userId);
    protected readonly hasGetRestrictionsError$ = this.store
        .select(IncidentCreatorState.getRestrictionsError)
        .pipe(map((error) => FunctionUtils.isTruthy(error)));

    protected readonly editedIncidentData$ = this.store.select(IncidentCreatorState.editedIncidentData).pipe(
        tap((incidentData) => {
            const customArea = incidentData?.customArea;
            if (customArea) {
                this.createCustomArea(SahMapUtils.createMutableMapArea(customArea));
            }
        })
    );
    protected readonly isProcessing$ = this.store.select(IncidentCreatorState.isProcessing).pipe(
        combineLatestWith(this.isEditMode$, this.store.select(IncidentCreatorState.editedIncidentData)),
        map(([isProcessing, isEditMode, editedIncidentData]) => isProcessing || (isEditMode && !editedIncidentData))
    );

    protected readonly selectedMapTool$ = this.localStore.selectByKey("selectedMapTool");
    protected readonly customArea$ = this.localStore.selectByKey("customArea");
    protected readonly isCustomIncidentAreaSelected$ = this.localStore.selectByKey("isCustomIncidentAreaSelected");
    protected readonly isDrawingHelpHidden$ = this.localStore.selectByKey("isDrawingHelpHidden");
    protected readonly activeStep$ = this.localStore.selectByKey("activeStep");

    private readonly areaLayer: FeatureGroup = new FeatureGroup();
    private mapInstance: Map | undefined;

    constructor(
        private readonly localStore: LocalComponentStore<IncidentCreatorComponentState>,
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly store: Store,
        private readonly translationHelper: TranslationHelperService,
        private readonly translocoService: TranslocoService,
        private readonly toastrService: ToastrService
    ) {
        this.localStore.setState({
            activeStep: IncidentCreatorStep.Data,
            selectedMapTool: this.CIRCLE_MAP_TOOL,
            customArea: undefined,
            restrictedArea: undefined,
            isDrawingHelpHidden: false,
            isCustomIncidentAreaSelected: false,
            editedIncidentId: undefined,
        });

        this.store.dispatch([
            new IncidentCreatorActions.GetAirOperationsCoordinators(),
            new IncidentCreatorActions.GetFlightsCoordinators(),
            new IncidentCreatorActions.GetInstitutions(),
            new IncidentCreatorActions.GetRestrictions(),
            new IncidentCreatorActions.GetUnits(),
        ]);

        this.loadIncidentDataIfEditMode();
    }

    public async ngAfterViewInit() {
        this.mapInstance = await this.incidentCreatorMapComponent?.leafletMapComponent?.getMap();

        this.mapInstance?.pm.setLang(DEFAULT_LANGUAGE);
        this.mapInstance?.addLayer(this.areaLayer);

        this.mapInstance?.on("mouseout", this.hideDrawingHelp, this);
        this.mapInstance?.on("mouseover", this.showDrawingHelp, this);
    }

    public ngOnDestroy() {
        this.mapInstance?.off("mouseout", this.hideDrawingHelp, this);
        this.mapInstance?.off("mouseover", this.showDrawingHelp, this);
    }

    protected createOrUpdateIncident(incidentFormValues: IncidentFormValues): void {
        const incidentId = this.localStore.selectSnapshotByKey("editedIncidentId");

        if (!incidentId) {
            this.createIncident(incidentFormValues);

            return;
        }

        this.updateIncident(incidentId, incidentFormValues);
    }

    protected cancel(): void {
        this.store.dispatch(IncidentCreatorActions.ResetState);
        this.router.navigateByUrl(DASHBOARD_URL);
    }

    protected selectDrawingTool(tool: MapTool): void {
        this.localStore.patchState({ selectedMapTool: tool });
    }

    protected createCustomArea(area: MapArea): void {
        this.localStore.patchState({ customArea: area });
        this.areaLayer.addLayer(area);
        this.mapInstance?.fire(CustomLeafletMapEvent.LoadArea, { area });
    }

    protected removeCustomArea(): void {
        const customArea = this.localStore.selectSnapshotByKey("customArea");

        if (customArea) {
            this.areaLayer.removeLayer(customArea);
        }

        this.localStore.patchState({ customArea: undefined });
    }

    protected updateIncidentAreaType(isCustomIncidentAreaSelected: boolean): void {
        const { customArea, restrictedArea } = this.localStore.get();

        this.localStore.patchState({ isCustomIncidentAreaSelected });
        this.areaLayer.clearLayers();

        if (isCustomIncidentAreaSelected) {
            this.displayCustomAreaIfPresent(customArea);

            return;
        }

        this.displayRestrictedAreaIfPresent(restrictedArea);
    }

    protected async updateRestrictedArea(newRestriction: Restriction | null | undefined): Promise<void> {
        if (!newRestriction) {
            return;
        }

        this.areaLayer.clearLayers();

        if (!newRestriction.area && newRestriction.id) {
            await firstValueFrom(
                this.store.dispatch(new IncidentCreatorActions.GetRestrictionArea(newRestriction.id)).pipe(untilDestroyed(this))
            );

            const error = this.store.selectSnapshot(IncidentCreatorState.getRestrictionAreaError);
            if (error) {
                this.toastrService.error(
                    this.translocoService.translate("sahClientLibIncidentCreator.incidentCreator.dataPanel.getRestrictionAreaError")
                );

                return;
            }

            newRestriction = this.store
                .selectSnapshot(IncidentCreatorState.restrictions)
                ?.find((restriction) => restriction.id === newRestriction?.id);
        }

        const area = newRestriction?.area;
        if (!area) {
            return;
        }

        this.displayRestrictedAreaIfPresent(area);
        this.localStore.patchState({ restrictedArea: area });
    }

    protected createTeams(teams: TeamCreatorFormValues[]): void {
        const createdIncidentId = this.store.selectSnapshot(IncidentCreatorState.createdIncidentId);
        if (!createdIncidentId) {
            return;
        }

        this.store
            .dispatch(new IncidentCreatorActions.CreateTeams(createdIncidentId, teams))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(IncidentCreatorState.createTeamsError);

                if (error) {
                    this.toastrService.error(
                        this.translocoService.translate("sahClientLibIncidentCreator.incidentCreator.teamsPanel.createTeamsError")
                    );

                    return;
                }

                this.toastrService.success(
                    this.translocoService.translate("sahClientLibIncidentCreator.incidentCreator.teamsPanel.createTeamsSuccessMessage")
                );
                this.router.navigateByUrl(DASHBOARD_URL);
            });
    }

    protected skipStep(): void {
        this.router.navigateByUrl(DASHBOARD_URL);
    }

    private displayCustomAreaIfPresent(area: MapArea | undefined): void {
        if (!area) {
            return;
        }

        area.setStyle(DEFAULT_MAP_AREA_PATH_OPTIONS);
        this.areaLayer.addLayer(area);
        this.mapInstance?.fitBounds(this.areaLayer.getBounds());
    }

    private displayRestrictedAreaIfPresent(area: MapArea | undefined | null): void {
        if (!area) {
            return;
        }

        area = SahMapUtils.createMutableMapArea(area);
        area.setStyle(DEFAULT_MAP_AREA_PATH_OPTIONS);
        this.areaLayer.addLayer(area);
        this.mapInstance?.fitBounds(this.areaLayer.getBounds());
    }

    private createIncident(incidentFormValues: IncidentFormValues): void {
        this.store
            .dispatch(new IncidentCreatorActions.CreateIncident(incidentFormValues))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(IncidentCreatorState.createIncidentError);

                if (error) {
                    switch (error.type) {
                        case IncidentCreatorErrorType.Conflict:
                            this.toastrService.error(this.translationHelper.selectSystemTranslation(error.messageKey, error.args));
                            break;
                        default:
                            this.toastrService.error(
                                this.translocoService.translate("sahClientLibIncidentCreator.incidentCreator.dataPanel.createIncidentError")
                            );
                    }

                    return;
                }

                this.toastrService.success(
                    this.translocoService.translate("sahClientLibIncidentCreator.incidentCreator.dataPanel.createIncidentSuccessMessage", {
                        name: incidentFormValues.name,
                    })
                );
                this.localStore.patchState({ activeStep: IncidentCreatorStep.Teams });
                this.store.dispatch(new IncidentCreatorActions.GetOperators());
            });
    }

    private updateIncident(incidentId: string, incidentFormValues: IncidentFormValues): void {
        this.store
            .dispatch(new IncidentCreatorActions.UpdateIncident(incidentId, incidentFormValues))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(IncidentCreatorState.editIncidentError);

                if (error) {
                    switch (error.type) {
                        case IncidentCreatorErrorType.Conflict:
                            this.toastrService.error(this.translationHelper.selectSystemTranslation(error.messageKey, error.args));
                            break;
                        default:
                            this.toastrService.error(
                                this.translocoService.translate("sahClientLibIncidentCreator.incidentCreator.dataPanel.updateIncidentError")
                            );
                    }

                    return;
                }

                this.toastrService.success(
                    this.translocoService.translate("sahClientLibIncidentCreator.incidentCreator.dataPanel.updateIncidentSuccessMessage", {
                        name: incidentFormValues.name,
                    })
                );
                this.router.navigateByUrl(DASHBOARD_URL);
            });
    }

    private loadIncidentDataIfEditMode(): void {
        this.route.params
            .pipe(
                map((params) => params["id"]),
                first(FunctionUtils.isTruthy),
                switchMap((incidentId) => {
                    this.localStore.patchState({ editedIncidentId: incidentId });

                    return this.store.dispatch(new IncidentCreatorActions.GetIncident(incidentId));
                }),
                filter(() => !!this.store.selectSnapshot(IncidentCreatorState.editedIncidentDataError)),
                switchMap(() =>
                    this.translationHelper.waitForTranslation(
                        "sahClientLibIncidentCreator.incidentCreator.dataPanel.getEditedIncidentDataError"
                    )
                ),
                first(),
                tap((errorMessage) => this.toastrService.error(errorMessage)),
                untilDestroyed(this)
            )
            .subscribe();
    }

    private hideDrawingHelp(): void {
        this.localStore.patchState({ isDrawingHelpHidden: true });
    }

    private showDrawingHelp(): void {
        this.localStore.patchState({ isDrawingHelpHidden: false });
    }
}
