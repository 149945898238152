import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";
import { ONLY_WHITE_SPACES_VALIDATION_PATTERN } from "@dtm-frontend/shared/utils";

const MAX_NAME_LENGTH = 50;

@Component({
    selector: "sah-client-lib-area-name-change-dialog",
    templateUrl: "./area-name-change-dialog.component.html",
    styleUrls: ["./area-name-change-dialog.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AreaNameChangeDialogComponent {
    protected readonly areaNameControl = new FormControl<string>("", {
        validators: [Validators.required, Validators.pattern(ONLY_WHITE_SPACES_VALIDATION_PATTERN), Validators.maxLength(MAX_NAME_LENGTH)],
        nonNullable: true,
    });

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: { areaName: string },
        private readonly dialogRef: MatDialogRef<AreaNameChangeDialogComponent>
    ) {
        if (data.areaName) {
            this.areaNameControl.setValue(data.areaName);
        }
    }

    public onConfirmClick(): void {
        this.areaNameControl.markAsTouched();

        if (this.areaNameControl.invalid) {
            return;
        }

        this.dialogRef.close(this.areaNameControl.value.trim());
    }
}
