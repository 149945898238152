import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import {
    AreaEntity,
    convertAreaEntityToMapArea,
    convertHandDrawingEntityResponseToHandDrawPolyline,
    HandDrawingEntityResponse,
    HandDrawingPolyline,
    IncidentErrorType,
    MapArea,
    Task,
    TaskCreatorFormValues,
    TaskErrorType,
    TaskStatus,
    Team,
} from "@dtm-frontend/search-and-help-shared-lib/incident";
import { ChipOption } from "@dtm-frontend/shared/ui";
import { StringUtils } from "@dtm-frontend/shared/utils";
import { catchError, Observable, throwError } from "rxjs";
import { map } from "rxjs/operators";
import { Pilot, PilotsUav, TeamCreatorFormValues } from "../../shared/models/team.models";
import { convertTeamCreatorFormValuesListToCreateTeamsRequestPayload } from "../../shared/services/team-shared.converters";
import { IncidentEndpoints, INCIDENT_ENDPOINTS } from "../incident.tokens";
import {
    convertErrorResponseBodyToIncidentError,
    convertGetPilotsResponseBodyToPilotsArray,
    convertGetPilotsUavsResponseBodyToPilotUavsArray,
    convertGetTeamCreatorHintsResponseBodyToChipOptionArray,
    convertMapAreaToAreaEntity,
    convertTaskCreatorFormValuesToCreateOrUpdateTaskRequestPayload,
    GetOperatorsResponseBody,
    GetPilotsResponseBody,
    GetPilotUavsResponseBody,
    GetTeamCreatorHintsResponseBody,
} from "./incident-api.converters";

@Injectable()
export class IncidentApiService {
    constructor(@Inject(INCIDENT_ENDPOINTS) private readonly endpoints: IncidentEndpoints, private readonly http: HttpClient) {}

    public createTask(incidentId: string, formValues: TaskCreatorFormValues): Observable<void> {
        return this.http
            .post<void>(
                StringUtils.replaceInTemplate(this.endpoints.createTask, { incidentId }),
                convertTaskCreatorFormValuesToCreateOrUpdateTaskRequestPayload(formValues)
            )
            .pipe(catchError(() => throwError(() => ({ type: TaskErrorType.Unknown }))));
    }

    public updateTask(incidentId: string, taskId: string, formValues: TaskCreatorFormValues): Observable<void> {
        return this.http
            .put<void>(
                StringUtils.replaceInTemplate(this.endpoints.updateTask, { incidentId, taskId }),
                convertTaskCreatorFormValuesToCreateOrUpdateTaskRequestPayload(formValues)
            )
            .pipe(catchError(() => throwError(() => ({ type: TaskErrorType.Unknown }))));
    }

    public updateTaskStatus(taskStatus: TaskStatus, incidentId: string, taskId: string): Observable<void> {
        return this.http
            .put<void>(StringUtils.replaceInTemplate(this.endpoints.updateTaskStatus, { incidentId, taskId }), {
                status: taskStatus,
            })
            .pipe(catchError(() => throwError(() => ({ type: TaskErrorType.Unknown }))));
    }

    public removeTask(taskId: string, incidentId: string): Observable<void> {
        return this.http
            .delete<void>(StringUtils.replaceInTemplate(this.endpoints.removeTask, { incidentId, taskId }))
            .pipe(catchError(() => throwError(() => ({ type: IncidentErrorType.Unknown }))));
    }

    public createArea(area: MapArea, incidentId: string): Observable<MapArea> {
        return this.http
            .post<AreaEntity>(StringUtils.replaceInTemplate(this.endpoints.createArea, { incidentId }), convertMapAreaToAreaEntity(area))
            .pipe(
                map((areaEntity) => convertAreaEntityToMapArea(areaEntity)),
                catchError(() => throwError(() => ({ type: IncidentErrorType.Unknown })))
            );
    }

    public updateArea(area: MapArea, incidentId: string): Observable<MapArea> {
        return this.http
            .put<AreaEntity>(
                StringUtils.replaceInTemplate(this.endpoints.updateArea, { incidentId, areaId: area.data?.id ?? "" }),
                convertMapAreaToAreaEntity(area)
            )
            .pipe(
                map((areaEntity) => convertAreaEntityToMapArea(areaEntity)),
                catchError(() => throwError(() => ({ type: IncidentErrorType.Unknown })))
            );
    }

    public removeArea(area: MapArea, incidentId: string): Observable<void> {
        return this.http
            .delete<void>(StringUtils.replaceInTemplate(this.endpoints.removeArea, { incidentId, areaId: area.data?.id ?? "" }))
            .pipe(catchError(() => throwError(() => ({ type: IncidentErrorType.Unknown }))));
    }

    public createHandDrawing(drawing: HandDrawingPolyline, incidentId: string): Observable<HandDrawingPolyline> {
        return this.http
            .post<HandDrawingEntityResponse>(StringUtils.replaceInTemplate(this.endpoints.createHandDrawing, { incidentId }), {
                geometry: drawing.toGeoJSON().geometry,
            })
            .pipe(
                map((response) => convertHandDrawingEntityResponseToHandDrawPolyline(response)),
                catchError(() => throwError(() => ({ type: IncidentErrorType.Unknown })))
            );
    }

    public removeHandDrawing(drawingId: string, incidentId: string): Observable<void> {
        return this.http
            .delete<void>(StringUtils.replaceInTemplate(this.endpoints.removeHandDrawing, { incidentId, drawingId }))
            .pipe(catchError(() => throwError(() => ({ type: IncidentErrorType.Unknown }))));
    }

    public assignAreaToTask(incidentId: string, task: Task, area: MapArea): Observable<void> {
        return this.http
            .post<void>(StringUtils.replaceInTemplate(this.endpoints.assignAreaToTask, { incidentId, taskId: task.id }), {
                areaId: area.data?.id ?? "",
            })
            .pipe(catchError(() => throwError(() => ({ type: IncidentErrorType.Unknown }))));
    }

    public detachAreaFromTask(incidentId: string, task: Task, area: MapArea): Observable<void> {
        return this.http
            .delete<void>(StringUtils.replaceInTemplate(this.endpoints.assignAreaToTask, { incidentId, taskId: task.id }), {
                body: { areaId: area.data?.id ?? "" },
            })
            .pipe(catchError(() => throwError(() => ({ type: IncidentErrorType.Unknown }))));
    }

    public createTeams(incidentId: string, teams: TeamCreatorFormValues[]): Observable<void> {
        return this.http
            .post<void>(
                StringUtils.replaceInTemplate(this.endpoints.createTeams, { incidentId }),
                convertTeamCreatorFormValuesListToCreateTeamsRequestPayload(teams)
            )
            .pipe(catchError(() => throwError(() => ({ type: IncidentErrorType.Unknown }))));
    }

    public attachTeamToTask(incidentId: string, task: Task, team: Team): Observable<void> {
        return this.http
            .post<void>(StringUtils.replaceInTemplate(this.endpoints.attachTeamToTask, { incidentId, taskId: task.id }), {
                teamId: team.id,
            })
            .pipe(catchError(() => throwError(() => ({ type: IncidentErrorType.Unknown }))));
    }

    public detachTeamFromTask(incidentId: string, task: Task): Observable<void> {
        return this.http
            .delete<void>(StringUtils.replaceInTemplate(this.endpoints.detachTeamFromTask, { incidentId, taskId: task.id }))
            .pipe(catchError((error) => throwError(() => convertErrorResponseBodyToIncidentError(error))));
    }

    public getTeamCreatorHints(incidentId: string): Observable<ChipOption[]> {
        return this.http
            .get<GetTeamCreatorHintsResponseBody>(StringUtils.replaceInTemplate(this.endpoints.getTeamCreatorHints, { incidentId }))
            .pipe(
                map((response) => convertGetTeamCreatorHintsResponseBodyToChipOptionArray(response)),
                catchError(() => throwError(() => ({ type: IncidentErrorType.Unknown })))
            );
    }

    public getOperators(): Observable<GetOperatorsResponseBody> {
        return this.http
            .get<GetOperatorsResponseBody>(this.endpoints.getOperators)
            .pipe(catchError(() => throwError(() => ({ type: IncidentErrorType.Unknown }))));
    }

    public getPilots(operatorId: string): Observable<Pilot[]> {
        return this.http.get<GetPilotsResponseBody>(this.endpoints.getPilots, { params: { operatorId } }).pipe(
            map((response) => convertGetPilotsResponseBodyToPilotsArray(response)),
            catchError(() => throwError(() => ({ type: IncidentErrorType.Unknown })))
        );
    }

    public getPilotsUavs(pilotId: string): Observable<PilotsUav[]> {
        return this.http.get<GetPilotUavsResponseBody>(StringUtils.replaceInTemplate(this.endpoints.getPilotsUavs, { pilotId })).pipe(
            map((response) => convertGetPilotsUavsResponseBodyToPilotUavsArray(response)),
            catchError(() => throwError(() => ({ type: IncidentErrorType.Unknown })))
        );
    }

    public removeTeam(incidentId: string, teamId: string): Observable<void> {
        return this.http
            .delete<void>(StringUtils.replaceInTemplate(this.endpoints.removeTeam, { incidentId, teamId }))
            .pipe(catchError((error) => throwError(() => convertErrorResponseBodyToIncidentError(error))));
    }

    public rejectPilotTask(incidentId: string, taskId: string, rejectionReason: string | null): Observable<void> {
        return this.http
            .put<void>(StringUtils.replaceInTemplate(this.endpoints.updateTaskStatus, { incidentId, taskId }), {
                status: TaskStatus.Rejected,
                rejectionReason,
            })
            .pipe(catchError(() => throwError(() => ({ type: TaskErrorType.Unknown }))));
    }
}
