import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { ActionMarkerMapTool, ACTION_MARKER_TOOLS, MapTool, MapToolName } from "../../../models/incident-map.models";

interface ActionMarkersSelectListComponentState {
    selectedActionMarker: MapToolName | undefined;
}

@Component({
    selector: "sah-shared-lib-action-markers-select-list",
    templateUrl: "action-markers-select-list.component.html",
    styleUrls: ["action-markers-select-list.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class ActionMarkersSelectListComponent {
    @Input() public set selectedActionMarker(value: MapToolName | undefined) {
        this.localStore.patchState({ selectedActionMarker: value });
    }

    @Output() protected readonly markerSelect = new EventEmitter<MapTool>();

    protected readonly ACTION_MARKER_TOOLS = ACTION_MARKER_TOOLS;

    protected readonly selectedActionMarker$ = this.localStore.selectByKey("selectedActionMarker");

    constructor(private readonly localStore: LocalComponentStore<ActionMarkersSelectListComponentState>) {
        this.localStore.setState({
            selectedActionMarker: undefined,
        });
    }

    protected isMarkerSelected(selectedMarker: MapToolName | undefined, marker: ActionMarkerMapTool): boolean {
        return !!selectedMarker && selectedMarker === marker.name;
    }
}
