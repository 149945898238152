import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { MapArea } from "@dtm-frontend/search-and-help-shared-lib/incident";
import { StringUtils } from "@dtm-frontend/shared/utils";
import { catchError, Observable, throwError } from "rxjs";
import { map } from "rxjs/operators";
import { Pilot, PilotsUav, TeamCreatorFormValues } from "../../shared/models/team.models";
import { convertTeamCreatorFormValuesListToCreateTeamsRequestPayload } from "../../shared/services/team-shared.converters";
import { IncidentCreatorEndpoints, INCIDENT_CREATOR_ENDPOINTS } from "../incident-creator.tokens";
import {
    Coordinator,
    CoordinatorRole,
    IncidentCreatorErrorType,
    IncidentFormValues,
    Institution,
    Restriction,
    Unit,
} from "../models/incident-creator.models";
import {
    convertErrorResponseBodyToIncidentCreatorError,
    convertGetCoordinatorsResponseBodyToCoordinatorsArray,
    convertGetIncidentResponseBodyToIncidentFormValues,
    convertGetPilotsResponseBodyToPilotsArray,
    convertGetPilotsUavsResponseBodyToPilotUavsArray,
    convertGetRestrictionAreaResponseBodyToMapArea,
    convertGetRestrictionResponseBodyToRestrictionList,
    convertIncidentFormValuesToCreateOrUpdateIncidentRequestPayload,
    convertUnitsMapToUnitList,
    CreateOrUpdateIncidentResponseBody,
    GetCoordinatorsResponseBody,
    GetIncidentResponseBody,
    GetOperatorsResponseBody,
    GetPilotsResponseBody,
    GetPilotUavsResponseBody,
    GetRestrictionAreaResponseBody,
    GetRestrictionsResponseBody,
} from "./incident-creator-api.converters";

@Injectable()
export class IncidentCreatorApiService {
    constructor(
        @Inject(INCIDENT_CREATOR_ENDPOINTS) private readonly endpoints: IncidentCreatorEndpoints,
        private readonly http: HttpClient
    ) {}

    public createIncident(formValues: IncidentFormValues): Observable<CreateOrUpdateIncidentResponseBody> {
        return this.http
            .post<CreateOrUpdateIncidentResponseBody>(
                this.endpoints.createIncident,
                convertIncidentFormValuesToCreateOrUpdateIncidentRequestPayload(formValues)
            )
            .pipe(catchError((error) => throwError(() => convertErrorResponseBodyToIncidentCreatorError(error))));
    }

    public updateIncident(incidentId: string, formValues: IncidentFormValues): Observable<CreateOrUpdateIncidentResponseBody> {
        return this.http
            .put<CreateOrUpdateIncidentResponseBody>(
                StringUtils.replaceInTemplate(this.endpoints.updateIncident, { incidentId }),
                convertIncidentFormValuesToCreateOrUpdateIncidentRequestPayload(formValues)
            )
            .pipe(catchError((error) => throwError(() => convertErrorResponseBodyToIncidentCreatorError(error))));
    }

    public getIncident(incidentId: string): Observable<IncidentFormValues> {
        return this.http.get<GetIncidentResponseBody>(StringUtils.replaceInTemplate(this.endpoints.getIncident, { incidentId })).pipe(
            map((response) => convertGetIncidentResponseBodyToIncidentFormValues(response)),
            catchError(() => throwError(() => ({ type: IncidentCreatorErrorType.Unknown })))
        );
    }

    public createTeams(incidentId: string, teams: TeamCreatorFormValues[]): Observable<void> {
        return this.http
            .post<void>(
                StringUtils.replaceInTemplate(this.endpoints.createTeams, { incidentId }),
                convertTeamCreatorFormValuesListToCreateTeamsRequestPayload(teams)
            )
            .pipe(catchError(() => throwError(() => ({ type: IncidentCreatorErrorType.Unknown }))));
    }

    public getAirOperationsCoordinators(): Observable<Coordinator[]> {
        return this.http
            .get<GetCoordinatorsResponseBody>(this.endpoints.getCoordinators, {
                params: { role: CoordinatorRole.AirOperationsCoordinator },
            })
            .pipe(
                map((response) => convertGetCoordinatorsResponseBodyToCoordinatorsArray(response)),
                catchError(() => throwError(() => ({ type: IncidentCreatorErrorType.Unknown })))
            );
    }

    public getFlightsCoordinators(): Observable<Coordinator[]> {
        return this.http
            .get<GetCoordinatorsResponseBody>(this.endpoints.getCoordinators, {
                params: { role: CoordinatorRole.FlightsDirector },
            })
            .pipe(
                map((response) => convertGetCoordinatorsResponseBodyToCoordinatorsArray(response)),
                catchError(() => throwError(() => ({ type: IncidentCreatorErrorType.Unknown })))
            );
    }

    public getInstitutions(): Observable<Institution[]> {
        return this.http
            .get<Institution[]>(this.endpoints.getInstitutions)
            .pipe(catchError(() => throwError(() => ({ type: IncidentCreatorErrorType.Unknown }))));
    }

    public getUnits(): Observable<Unit[]> {
        return this.http.get<Record<string, string>>(this.endpoints.getUnits).pipe(
            map((unitsMap) => convertUnitsMapToUnitList(unitsMap)),
            catchError(() => throwError(() => ({ type: IncidentCreatorErrorType.Unknown })))
        );
    }

    public getRestrictions(): Observable<Restriction[]> {
        return this.http
            .get<GetRestrictionsResponseBody>(this.endpoints.getRestrictions)
            .pipe(map((response) => convertGetRestrictionResponseBodyToRestrictionList(response)));
    }

    public getRestrictionArea(restrictionId: string): Observable<MapArea | undefined> {
        return this.http
            .get<GetRestrictionAreaResponseBody>(StringUtils.replaceInTemplate(this.endpoints.getRestrictionArea, { restrictionId }))
            .pipe(map((response) => convertGetRestrictionAreaResponseBodyToMapArea(response)));
    }

    public getOperators(): Observable<GetOperatorsResponseBody> {
        return this.http
            .get<GetOperatorsResponseBody>(this.endpoints.getOperators)
            .pipe(catchError(() => throwError(() => ({ type: IncidentCreatorErrorType.Unknown }))));
    }

    public getPilots(operatorId: string): Observable<Pilot[]> {
        return this.http.get<GetPilotsResponseBody>(this.endpoints.getPilots, { params: { operatorId } }).pipe(
            map((response) => convertGetPilotsResponseBodyToPilotsArray(response)),
            catchError(() => throwError(() => ({ type: IncidentCreatorErrorType.Unknown })))
        );
    }

    public getPilotsUavs(pilotId: string): Observable<PilotsUav[]> {
        return this.http.get<GetPilotUavsResponseBody>(StringUtils.replaceInTemplate(this.endpoints.getPilotsUavs, { pilotId })).pipe(
            map((response) => convertGetPilotsUavsResponseBodyToPilotUavsArray(response)),
            catchError(() => throwError(() => ({ type: IncidentCreatorErrorType.Unknown })))
        );
    }
}
