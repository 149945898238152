import { ChangeDetectionStrategy, Component } from "@angular/core";
import { MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { LegacyPageEvent as PageEvent } from "@angular/material/legacy-paginator";
import { IncidentListAction, IncidentListActionType, IncidentListItem } from "@dtm-frontend/search-and-help-shared-lib/incident";
import { ButtonTheme, ConfirmationDialogComponent, DialogService } from "@dtm-frontend/shared/ui";
import { TranslationHelperService } from "@dtm-frontend/shared/ui/i18n";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@ngneat/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { ToastrService } from "ngx-toastr";
import { first } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { SahAbsolutePath } from "../../../shared/defaults/paths";
import { DashboardErrorType, IncidentListType } from "../../models/dasboard.models";
import { DashboardActions } from "../../state/dashboard.actions";
import { DashboardState } from "../../state/dashboard.state";

interface DashboardComponentState {
    myIncidentsSelectedTabIndex: number;
}

enum DashboardTab {
    MyActiveIncidents = 0,
    MyPlannedIncidents = 1,
    MyCompletedIncidents = 2,
}

@UntilDestroy()
@Component({
    selector: "sah-client-lib-dashboard",
    templateUrl: "./dashboard.component.html",
    styleUrls: ["./dashboard.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class DashboardComponent {
    protected readonly SahAbsolutePath = SahAbsolutePath;
    protected readonly IncidentListType = IncidentListType;
    protected readonly MY_ACTIVE_INCIDENTS_ACTIONS: IncidentListAction[] = [
        {
            iconName: "arrow-go",
            type: IncidentListActionType.Link,
            labelKey: "sahClientLibDashboard.incidentList.goToIncidentActionLabel",
            link: SahAbsolutePath.ActiveIncident,
        },
        {
            iconName: "edit",
            type: IncidentListActionType.Link,
            labelKey: "sahClientLibDashboard.incidentList.goToIncidentEditActionLabel",
            link: SahAbsolutePath.EditIncident,
        },
        {
            iconName: "file-text",
            type: IncidentListActionType.Button,
            labelKey: "sahClientLibDashboard.incidentList.downloadIncidentReportActionLabel",
            run: (incident) => this.downloadIncidentReport(incident),
        },
        {
            iconName: "check",
            type: IncidentListActionType.Button,
            labelKey: "sahClientLibDashboard.incidentList.completeIncidentActionLabel",
            run: (incident) => this.completeIncident(incident),
        },
    ];
    protected readonly MY_PLANNED_INCIDENT_ACTIONS: IncidentListAction[] = [
        {
            iconName: "arrow-go",
            type: IncidentListActionType.Link,
            labelKey: "sahClientLibDashboard.incidentList.goToIncidentActionLabel",
            link: SahAbsolutePath.PlannedIncident,
        },
        {
            iconName: "edit",
            type: IncidentListActionType.Link,
            labelKey: "sahClientLibDashboard.incidentList.goToIncidentEditActionLabel",
            link: SahAbsolutePath.EditIncident,
        },
        {
            iconName: "play-fill",
            type: IncidentListActionType.Button,
            labelKey: "sahClientLibDashboard.incidentList.activateIncidentActionLabel",
            run: (incident) => this.activateIncident(incident),
        },
    ];

    protected readonly MY_COMPLETED_INCIDENT_ACTIONS: IncidentListAction[] = [
        {
            iconName: "file-text",
            type: IncidentListActionType.Button,
            labelKey: "sahClientLibDashboard.incidentList.downloadIncidentReportActionLabel",
            run: (incident) => this.downloadIncidentReport(incident),
        },
    ];

    protected readonly myIncidentsSelectedTabIndex$ = this.localStore.selectByKey("myIncidentsSelectedTabIndex");

    protected readonly isProcessing$ = this.store.select(DashboardState.isProcessing);
    protected readonly myActiveIncidents$ = this.store.select(DashboardState.myActiveIncidents);
    protected readonly myActiveIncidentsPage$ = this.store.select(DashboardState.myActiveIncidentsPage);
    protected readonly hasMyActiveIncidentsError$ = this.store.select(DashboardState.myActiveIncidentsError).pipe(map((error) => !!error));
    protected readonly myPlannedIncidents$ = this.store.select(DashboardState.myPlannedIncidents);
    protected readonly myPlannedIncidentsPage$ = this.store.select(DashboardState.myPlannedIncidentsPage);
    protected readonly hasMyPlannedIncidentsError$ = this.store
        .select(DashboardState.myPlannedIncidentsError)
        .pipe(map((error) => !!error));
    protected readonly myCompletedIncidents$ = this.store.select(DashboardState.myCompletedIncidents);
    protected readonly myCompletedIncidentsPage$ = this.store.select(DashboardState.myCompletedIncidentsPage);
    protected readonly hasMyCompletedIncidentsError$ = this.store
        .select(DashboardState.myCompletedIncidentsError)
        .pipe(map((error) => !!error));

    constructor(
        private readonly dialogService: DialogService,
        private readonly localStore: LocalComponentStore<DashboardComponentState>,
        private readonly store: Store,
        private readonly translationHelper: TranslationHelperService,
        private readonly translocoService: TranslocoService,
        private readonly toastrService: ToastrService
    ) {
        this.localStore.setState({
            myIncidentsSelectedTabIndex: 0,
        });
        this.getList(IncidentListType.MyActiveIncidents);
    }

    protected changeMyIncidentsTab(tabIndex: number) {
        switch (tabIndex) {
            case DashboardTab.MyPlannedIncidents:
                this.localStore.patchState({ myIncidentsSelectedTabIndex: DashboardTab.MyPlannedIncidents });
                this.getList(IncidentListType.MyPlannedIncidents);
                break;
            case DashboardTab.MyCompletedIncidents:
                this.localStore.patchState({
                    myIncidentsSelectedTabIndex: DashboardTab.MyCompletedIncidents,
                });
                this.getList(IncidentListType.MyCompletedIncidents);
                break;
            default:
                this.localStore.patchState({
                    myIncidentsSelectedTabIndex: DashboardTab.MyActiveIncidents,
                });
                this.getList(IncidentListType.MyActiveIncidents);
        }
    }

    protected getList(listType: IncidentListType, pageEvent?: PageEvent): void {
        switch (listType) {
            case IncidentListType.MyActiveIncidents:
                this.store.dispatch(new DashboardActions.GetMyActiveIncidents(pageEvent));
                break;
            case IncidentListType.MyPlannedIncidents:
                this.store.dispatch(new DashboardActions.GetMyPlannedIncidents(pageEvent));
                break;
            case IncidentListType.MyCompletedIncidents:
                this.store.dispatch(new DashboardActions.GetMyCompletedIncidents(pageEvent));
                break;
        }
    }

    private activateIncident(incident: IncidentListItem): void {
        this.openConfirmationDialog(
            this.translocoService.translate("sahClientLibDashboard.incidentList.confirmActivationTitle", {
                incidentName: incident.name,
            })
        )
            .afterClosed()
            .pipe(
                RxjsUtils.filterFalsy(),
                switchMap(() => this.store.dispatch(new DashboardActions.ActivateIncident(incident.id))),
                untilDestroyed(this)
            )
            .subscribe(() => {
                const error = this.store.selectSnapshot(DashboardState.incidentActivationError);

                if (error) {
                    switch (error.type) {
                        case DashboardErrorType.Conflict:
                            this.toastrService.error(this.translationHelper.selectSystemTranslation(error.messageKey, error.args));
                            break;
                        default:
                            this.toastrService.error(
                                this.translocoService.translate("sahClientLibDashboard.incidentList.activationErrorMessage", {
                                    incidentName: incident.name,
                                })
                            );
                    }

                    return;
                }

                this.toastrService.success(
                    this.translocoService.translate("sahClientLibDashboard.incidentList.activationSuccessMessage", {
                        incidentName: incident.name,
                    })
                );
                this.getList(IncidentListType.MyPlannedIncidents);
            });
    }

    private completeIncident(incident: IncidentListItem): void {
        this.openConfirmationDialog(
            this.translocoService.translate("sahClientLibDashboard.incidentList.confirmCompletionTitle", {
                incidentName: incident.name,
            })
        )
            .afterClosed()
            .pipe(
                RxjsUtils.filterFalsy(),
                switchMap(() => this.store.dispatch(new DashboardActions.CompleteIncident(incident.id))),
                untilDestroyed(this)
            )
            .subscribe(() => {
                const error = this.store.selectSnapshot(DashboardState.incidentCompletionError);

                if (error) {
                    this.toastrService.error(
                        this.translocoService.translate("sahClientLibDashboard.incidentList.completionErrorMessage", {
                            incidentName: incident.name,
                        })
                    );

                    return;
                }

                this.toastrService.success(
                    this.translocoService.translate("sahClientLibDashboard.incidentList.completionSuccessMessage", {
                        incidentName: incident.name,
                    })
                );
                this.getList(IncidentListType.MyActiveIncidents);
            });
    }

    private openConfirmationDialog(title: string): MatDialogRef<ConfirmationDialogComponent> {
        return this.dialogService.open(ConfirmationDialogComponent, {
            data: {
                titleText: title,
                confirmationText: this.translocoService.translate("sahClientLibDashboard.incidentList.confirmMessage"),
                confirmButtonLabel: this.translocoService.translate("sahClientLibDashboard.incidentList.confirmButtonLabel"),
                declineButtonLabel: this.translocoService.translate("sahClientLibDashboard.incidentList.declineButtonLabel"),
                theme: ButtonTheme.Warn,
            },
            disableClose: true,
        });
    }

    private downloadIncidentReport(incident: IncidentListItem): void {
        this.store
            .dispatch(
                new DashboardActions.DownloadIncidentReport(
                    incident.id,
                    this.translocoService.translate("sahClientLibDashboard.incidentList.reportNameLabel", {
                        incidentName: incident.name,
                    })
                )
            )
            .pipe(first(), untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(DashboardState.downloadIncidentReportError);
                if (error) {
                    this.toastrService.error(
                        this.translocoService.translate("sahClientLibDashboard.incidentList.downloadIncidentReportError")
                    );
                }
            });
    }
}
