import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { AbstractControl, FormControl, FormGroup } from "@angular/forms";
import { IncidentStatus, MapFilter, MapFiltersState } from "@dtm-frontend/search-and-help-shared-lib/incident";
import { DEFAULT_DEBOUNCE_TIME, LocalComponentStore } from "@dtm-frontend/shared/utils";
import { distinctUntilChanged, Observable, tap } from "rxjs";
import { debounceTime, map } from "rxjs/operators";

interface MapFiltersPanelComponentState {
    isOpen: boolean;
    areFiltersActive: boolean;
    incidentStatus: IncidentStatus | undefined;
}

@Component({
    selector:
        "sah-client-lib-map-filters-panel[incidentStatus][mapFiltersState][isOpen][isAreaDrawEnabled][isHandDrawEnabled][isActionMarkerEnabled]",
    templateUrl: "map-filters-panel.component.html",
    styleUrls: ["map-filters-panel.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class MapFiltersPanelComponent {
    protected readonly IncidentStatus = IncidentStatus;
    protected readonly filtersForm = new FormGroup({
        actionMarkers: new FormControl<boolean>(false, { nonNullable: true }),
        areas: new FormControl<boolean>(false, { nonNullable: true }),
        areasWithCompletedTasksOnly: new FormControl<boolean>(false, { nonNullable: true }),
        areasWithPlannedTasksOnly: new FormControl<boolean>(false, { nonNullable: true }),
        handDrawingsAndNotes: new FormControl<boolean>(false, { nonNullable: true }),
    });

    protected readonly isOpen$ = this.localStore.selectByKey("isOpen");
    protected readonly incidentStatus$ = this.localStore.selectByKey("incidentStatus");
    protected readonly areFiltersActive$ = this.localStore.selectByKey("areFiltersActive");

    @Input() public set incidentStatus(value: IncidentStatus | undefined) {
        this.localStore.patchState({ incidentStatus: value });
        const { areasWithCompletedTasksOnly } = this.filtersForm.controls;

        if (value === IncidentStatus.Planned && areasWithCompletedTasksOnly.enabled) {
            areasWithCompletedTasksOnly.disable();

            return;
        }

        if (areasWithCompletedTasksOnly.disabled) {
            areasWithCompletedTasksOnly.enable();
        }
    }

    @Input() public set mapFiltersState(value: MapFiltersState | undefined) {
        if (!value) {
            return;
        }

        this.filtersForm.patchValue({
            areas: value[MapFilter.Areas],
            areasWithCompletedTasksOnly: value[MapFilter.AreasWithCompletedTasksOnly],
            areasWithPlannedTasksOnly: value[MapFilter.AreasWithPlannedTasksOnly],
            handDrawingsAndNotes: value[MapFilter.HandDrawingsAndNotes],
            actionMarkers: value[MapFilter.ActionMarkers],
        });
    }

    @Input() public set isAreaDrawEnabled(value: BooleanInput) {
        this.toggleControl(this.filtersForm.controls.areas, coerceBooleanProperty(value));
    }

    @Input() public set isHandDrawEnabled(value: BooleanInput) {
        this.toggleControl(this.filtersForm.controls.handDrawingsAndNotes, coerceBooleanProperty(value));
    }

    @Input() public set isActionMarkerEnabled(value: BooleanInput) {
        this.toggleControl(this.filtersForm.controls.actionMarkers, coerceBooleanProperty(value));
    }

    @Input() public set isOpen(value: BooleanInput) {
        this.localStore.patchState({ isOpen: coerceBooleanProperty(value) });
    }

    @Output() public readonly mapFiltersStateUpdate: Observable<MapFiltersState> = this.filtersForm.valueChanges.pipe(
        debounceTime(DEFAULT_DEBOUNCE_TIME),
        distinctUntilChanged(
            (left, right) =>
                left.actionMarkers === right.actionMarkers &&
                left.areas === right.areas &&
                left.areasWithCompletedTasksOnly === right.areasWithCompletedTasksOnly &&
                left.areasWithPlannedTasksOnly === right.areasWithPlannedTasksOnly &&
                left.handDrawingsAndNotes === right.handDrawingsAndNotes
        ),
        tap((value) => {
            this.localStore.patchState({ areFiltersActive: Object.values(value).includes(true) });
        }),
        map((value) => ({
            [MapFilter.ActionMarkers]: !!value.actionMarkers,
            [MapFilter.Areas]: !!value.areas,
            [MapFilter.AreasWithCompletedTasksOnly]: !!value.areasWithCompletedTasksOnly,
            [MapFilter.AreasWithPlannedTasksOnly]: !!value.areasWithPlannedTasksOnly,
            [MapFilter.HandDrawingsAndNotes]: !!value.handDrawingsAndNotes,
        }))
    );
    @Output() public readonly panelOpen = new EventEmitter<void>();
    @Output() public readonly panelClose = new EventEmitter<void>();

    constructor(private readonly localStore: LocalComponentStore<MapFiltersPanelComponentState>) {
        this.localStore.setState({ isOpen: false, areFiltersActive: false, incidentStatus: undefined });
    }

    protected togglePanel(isCurrentlyOpen: boolean): void {
        if (isCurrentlyOpen) {
            this.panelClose.emit();
        } else {
            this.panelOpen.emit();
        }
    }

    protected toggleControl(control: AbstractControl, isDisabled: boolean): void {
        if (isDisabled) {
            control.setValue(false);
            control.disable();
        } else {
            control.enable();
        }
    }
}
